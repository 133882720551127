<template>
  <div class="container">
    <subtitle :iconImg="iconImg" name="潮位数据" />
    <div class="btn_group">
      <div class="btn_screen" @click="firstFullScreenShow()">详细数据</div>
      <div class="btn_site">
        <div class="tideLevelSelectd">{{ tideLevelDataType }}</div>
        <div class="tideLevelOptions">
          <p :class="{ active: tideLevelDataType === '实测数据' }" @click="changeChartData('实测数据')">实测数据</p>
          <p :class="{ active: tideLevelDataType === '预测数据' }" @click="changeChartData('预测数据')">预测数据</p>
        </div>
      </div>
    <!--      TODO 传感器维护中，数据正常后把right删掉-->
      <div v-show="tideLevelDataType === '实测数据'" class="date_str">
        {{ realTimeDateStr}}
      </div>
      <div v-show="tideLevelDataType === '预测数据'" class="date_str">
        {{ forecastDateStr}}
      </div>
    </div>
    <div style="width: 340px; height: 200px">
      <basic-chart :styles-data="rightFirstChartStyles" :series-data="rightFirstChartSeries" />
    </div>

    <el-dialog
      :show-close="false"
      :visible.sync="dialogVisible"
      :destroy-on-close="true"
      :append-to-body="true"
      width="60%"
      top="-30px"
      class="full_screen"
      @close="clearFullScreenData"
    >
      <div>
        <div class="dialog_title">
          <div class="title_left">
            <img src="../../../assets/images/overview/curve.png" alt />
            <span>{{ fullChartName }}</span>
            <span>-详细数据</span>
          </div>
          <div>
            <img src="../../../assets/images/overview/title.png" alt />
          </div>
        </div>
        <div class="content">
          <div class="charts_block">
            <span class="charts_title">实测数据</span>
            <basic-chart v-if="dialogVisible" :styles-data="fullScreenChartConfigs.real.styles" :series-data="fullScreenChartConfigs.real.series" />
          </div>
          <div class="charts_block" style="padding-top: 10px;">
            <span class="charts_title" style="margin-top: -10px;">预测数据</span>
            <basic-chart v-if="dialogVisible" :styles-data="fullScreenChartConfigs.forecast.styles" :series-data="fullScreenChartConfigs.forecast.series" />
          </div>

        </div>
        <div class="show_data_btn" @click="toggleDataTable">查看数据表格<span>{{isShowDataTable ? '▼' : '▲'}}</span></div>
        <div class="data_content" v-show="isShowDataTable">
          <div class="header">
            <span>序号</span>
            <span>日期时间</span>
            <span>岸基站(m)</span>
            <span>离岸站(m)</span>
          </div>
          <div class="items_scroll">
            <div class="items" v-for="(e,i) in currentTableData" :key="i">
              <span class="item">{{e.index}}</span>
              <span class="item">{{e.date}}</span>
              <span class="item">{{e.h1}}</span>
              <span class="item">{{e.h2}}</span>
            </div>
          </div>
        </div>
        <el-pagination
          class="pagination"
          layout="prev, pager, next"
          :total="total"
          :page-size="pageSize"
          :current-page="currentPage"
          :pager-count="pageCount"
          @current-change="handleCurrentChange"
        >
        </el-pagination>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import * as echarts from 'echarts'
import basicChart from '@/components/echarts/basic-chart.vue'
import subtitle from './subtitle.vue'
import request from '../../../utils/request'
import { default as mapApp } from '@/map/init.js'
export default {
  name: 'RightFirst',
  components: { basicChart, subtitle },
  data() {
    return {
      // 标题图片
      iconImg: require('@/assets/images/overview/潮位.png'),
      //实时和预测的两点颜色
      shoreRealColor:'#429ffe',
      buoyRealColor:'#40C4E8',
      shorePredictColor:'rgb(65, 196, 150,0.7)',
      buoyPredictColor:'rgb(128, 227, 193,0.7)',
      // 潮位数据
      tideLevel: null,
      // 潮位站点
      tideLevelSite: [
        {
          value: 11,
          label: '岸基站'
        }
      ],
      // 缩略图初始图表样式和数据项样式
      rightFirstChartStyles: {
        grid: {
          left: '0',
          right: '5%',
          top: '15%',
          bottom: '0',
          containLabel: true
        },
        tooltip: {
          trigger: 'axis',
          formatter: (params) => {
          let tempStr1 = `<span style="display:inline-block;margin-right:4px;border-radius:10px;width:10px;height:10px;background-color:${params[0].data.type==='real'?this.shoreRealColor:this.shorePredictColor};"></span>`
          let tempStr2 = `<span style="display:inline-block;margin-right:4px;border-radius:10px;width:10px;height:10px;background-color:${params[0].data.type==='real'?this.buoyRealColor:this.buoyPredictColor};"></span>`
            let strRes =
              params[0].data.date + ' ' + params[0].axisValue + ':00' +
              '<div><div style="display:flex"><div style="margin-right:10px">' +
              tempStr1 +
              params[0].seriesName +
              '：<span style="font-weight:bold">' +
              params[0].data.value +
              '</span>m' +
              '</div></div></div>' +
              '<div><div style="display:flex"><div style="margin-right:10px">' +
              tempStr2 +
              params[1].seriesName +
              '：<span style="font-weight:bold">' +
              params[1].data +
              '</span>m' +
              '</div><div>';
            return strRes
          }
        },
        xAxis: {
          type: 'category',
          data: [],
          boundaryGap: false,
          // nameLocation: 'right',
          axisLabel: {
            color: '#fff',
            fontSize: 10
            // align: 'right'
            // rotate: '45'
          }
        },
        yAxis: {
          type: 'value',
          name: 'm',
          nameTextStyle: {
            color: '#ffffff',
            align: 'right',
            fontSize: 10
          },
          min: 0,
          max: 5,
          interval: 1,
          axisLabel: {
            color: '#fff'
          },
          splitLine: {
            lineStyle: {
              type: 'dashed',
              color: '#29506C',
              opacity: 0.5
            }
          }
        }
      },
      rightFirstChartSeries: [
        {
          name: '岸基站',
          type: 'line',
          data: [],
          smooth: true,
          showSymbol: false,
          lineStyle: {
            color: this.shoreRealColor || '#429ffe',
            width: 2
          },
          areaStyle: {
            //颜色渐变函数 前四个参数分别表示四个位置依次为左、下、右、上
            color: this.shoreRealColor
          }
        },
        {
          name: '浮标站',
          type: 'line',
          data: [],
          smooth: true,
          showSymbol: false,
          lineStyle: {
            color: this.buoyRealColor || '#40C4E8',
            width: 2
          },
          areaStyle: {
            //颜色渐变函数 前四个参数分别表示四个位置依次为左、下、右、上
            color:this.buoyRealColor
          }
        }
      //以下是预测数据
      ],

      tideLevelSiteValue: 11,
      // 潮位日期
      tideLevelDate: [],
      // 日期选中值
      tideLevelDateValue: '',
      // 选择框是否开启
      isTideLevelDateSign: false,

      // 数据时间范围字符串
      realTimeDateStr: '',
      forecastDateStr: '',

      tideLevelDataType: '实测数据',

      fullChartName: '',
      // 全屏图表开关
      dialogVisible: false,

      // 全屏图表初始样式和数据项样式
      fullScreenChartStyles: null,
      fullScreenChartSeries: null,

      // 详细信息弹窗图表配置
      fullScreenChartConfigs: {
        real: {
          styles: null,
          series: null,
        },
        forecast: {
          styles: null,
          series: null,
        }
      },

      // 潮位数据图表
      isShowDataTable: true,
      tideLevelTableData: [],
      // 分页功能
      currentTableData: [],
      currentPage: 6,
      total: 288,
      pageSize: 24,
      // 最大页码按钮数量, 必须是大于等于 5 且小于等于 21 的奇数
      pageCount: 13,
    }
  },
  created() {
    this.getTideLevelData()
    this.tideTimer = setInterval(()=>{
      this.getTideLevelData()
      console.log("潮位数据已重新获取")
    },60*1000*2)
  },
  // 组件方法
  methods: {
    // 获取潮位数据
    async getTideLevelData() {
      try {
        const { data } = await request('fn/tidalLv', 24)
        this.tideLevel = data.data
        this.tideWindows = data.winList;

        // 更新缩略图数据
        this.changeChartData('实测数据')
        // 更新全屏图表数据
        this.updateFirstFullScreenData()
        // 更新日期时间轴
        this.setDateDateExtentStr();

        // console.log("数据已重新获取")
      } catch (err) {
        console.log('获取数据失败！', err)
      }
    },
    // 设置数据时间范围
    setDateDateExtentStr() {
      this.realTimeDateStr = this.getDateStr(-1) + ' 至 ' + this.getDateStr(0)
      this.forecastDateStr = this.getDateStr(0) + ' 至 ' + this.getDateStr(1)
    },
    // 根据日期偏移获取时间字符串
    getDateStr(dateOffset) {
      let currDate = new Date();
      if(dateOffset) {
        currDate.setDate(currDate.getDate() + dateOffset);
        return `${currDate.getFullYear()}-${currDate.getMonth() + 1}-${currDate.getDate()}`
      }else{
        return `${currDate.getFullYear()}-${currDate.getMonth() + 1}-${currDate.getDate()}`
      }
    },
    changeChartData(txt) {
      // 切换当前数据状态 实测|预测
      this.tideLevelDataType = txt
      if (this.tideLevel) {
        if (this.tideLevelDataType === '实测数据') {
          // console.log("点击了实测按钮")
          let tempIndex = this.tideLevel.tidalH1[0]['DATA_TIME'].indexOf('T')
          let tempXData = []
          let seriesData1 = []
          for (let i = 0; i < this.tideLevel.tidalH1.length; i++) {
            // 显示整小时的数据 this.tideLevel.tidalH1[i]['DATA_TIME'].charAt(tempIndex + 4) === '0'
            // 修改为以最近的数据为准，往前每小时显示一个
            if ( i % 6 === 5) {
              seriesData1.push({
                date: this.tideLevel.tidalH1[i]['DATA_TIME'].substring(0, 10),
                value: Number(this.tideLevel.tidalH1[i]['TIDALLEVEL']).toFixed(2),
                type: 'real'
              })
              let dateStr = this.tideLevel.tidalH1[i]['DATA_TIME'].substring(tempIndex + 1, tempIndex + 6)
              tempXData.push(dateStr)
            }
          }

          let seriesData2 = []
          for (let i = 0; i < this.tideLevel.tidalH2.length; i++) {
            if ( i % 6 === 5) {
              seriesData2.push(Number(this.tideLevel.tidalH2[i]['TIDALLEVEL']).toFixed(2))
            }
          }
          this.rightFirstChartSeries[0].data = seriesData1
          this.rightFirstChartSeries[0].lineStyle = {color:this.shoreRealColor}
          this.rightFirstChartSeries[0].areaStyle = {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
              { offset: 0, color: this.shoreRealColor},
              { offset: 1, color: 'transparent' }
            ])
          }
          // this.rightFirstChartSeries[0].timeMapping = timeMapping;

          this.rightFirstChartSeries[1].data = seriesData2
          this.rightFirstChartSeries[1].lineStyle = {color:this.buoyRealColor}
          this.rightFirstChartSeries[1].areaStyle = {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
              { offset: 0, color: this.buoyRealColor},
              { offset: 1, color: 'transparent' }
            ])
          }
          this.rightFirstChartStyles.xAxis.data = tempXData

          // 设置观测设备弹窗数据
          this.setDataDevicePopData(this.getCurrNearTimeData());
        }
        if (this.tideLevelDataType === '预测数据') {
          // console.log("点击了预测按钮")
          let tempIndex = this.tideLevel.tidalFore[0]['DATA_TIME'].indexOf('T')
          let tempXData = []
          let seriesData1 = []
          let seriesData2 = []
          for (let i = 0; i < this.tideLevel.tidalFore.length; i++) {
            if ( i % 6 === 5) {
              seriesData1.push({
                date: this.tideLevel.tidalFore[i]['DATA_TIME'].substring(0, 10),
                value:Number(this.tideLevel.tidalFore[i]['H1']).toFixed(2),
                type: 'forecast'
                })
              seriesData2.push(Number(this.tideLevel.tidalFore[i]['H2']).toFixed(2))
              let dateStr = this.tideLevel.tidalFore[i]['DATA_TIME'].substring(tempIndex + 1, tempIndex + 6)
              tempXData.push(dateStr)
            }
          }
          this.rightFirstChartSeries[0].data = seriesData1
          this.rightFirstChartSeries[0].lineStyle = {color:this.shorePredictColor}
          this.rightFirstChartSeries[0].areaStyle = {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
              { offset: 0, color: this.shorePredictColor},
              { offset: 1, color: 'transparent' }
            ])
          }

          this.rightFirstChartSeries[1].data = seriesData2
          this.rightFirstChartSeries[1].lineStyle = {color:this.buoyPredictColor}
          this.rightFirstChartSeries[1].areaStyle = {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
              { offset: 0, color: this.buoyPredictColor},
              { offset: 1, color: 'transparent' }
            ])
          }
          this.rightFirstChartStyles.xAxis.data = tempXData
        }
      }
    },

    // 获取离当前时间点最接近的数据
    getCurrNearTimeData() {
      let currDate = new Date();
      let timeIndex = 0;
      let tempMin = null;
      for (let i=0, len=this.tideLevel.tidalH1.length; i < len; i++) {
        let timeStr = this.tideLevel.tidalH1[i]['DATA_TIME'];
        let dateStr = `${timeStr.substring(0, 10)} ${timeStr.substring(11, 16)}`;
        let targetDate = new Date(dateStr);
        let minute = parseInt(Math.abs(currDate - targetDate) / 1000 / 60);
        if(!tempMin) tempMin = minute;
        if(minute < tempMin){
          timeIndex = i;
          tempMin = minute;
        }
      }
      return {
        tidalH1: this.tideLevel.tidalH1[timeIndex],
        tidalH2: this.tideLevel.tidalH2[timeIndex],
        tidalFore: this.tideLevel.tidalFore[timeIndex]
      }
    },

    // 设置数据设施弹窗数据
    setDataDevicePopData(data) {
      mapApp.dataDevicePopData.seaLevelData.realSeaLevel = [
        Number(data.tidalH1['TIDALLEVEL']).toFixed(2),
        Number(data.tidalH2['TIDALLEVEL']).toFixed(2),
      ];
      mapApp.dataDevicePopData.seaLevelData.forcastSeaLevel = [
        Number(data.tidalFore['H1']).toFixed(2),
        Number(data.tidalFore['H2']).toFixed(2)
      ];

      mapApp.dataDevicePopData.seaLevelData.timeStr = `${data.tidalH1['DATA_TIME'].substring(0, 10)}  ${data.tidalH1['DATA_TIME'].substring(11, 19)}`;
      // 缓存数据
      mapApp.storeDataDevicePopData();
    },

    // 更新全屏图表数据
    updateFirstFullScreenData(){
      this.tideLevelTableData.length = 0;

      for(let key in this.fullScreenChartConfigs){
        // 中转 styles
        this.fullScreenChartConfigs[key].styles = JSON.parse(JSON.stringify(this.rightFirstChartStyles));
        // 打印缩略图图表样式
        // console.log("图表样式",this.rightFirstChartSeries)
        this.fullScreenChartConfigs[key].styles.grid = {
          left: '0%',
          right: '2%',
          top: '10%',
          bottom: '10%',
          containLabel: true
        }
        // this.fullScreenChartConfigs[key].styles.legend = {
        //   right: '2%',
        //   top: '2%',
        //   formatter: key === "real" ? '实测数据' : '预测数据',
        //   // 设置文本为红色}],
        //   textStyle: {
        //     color: '#fff'
        //   }
        // }
        this.fullScreenChartConfigs[key].styles.dataZoom = [
          {
            type: 'inside',
            start: 0,
            end: 100
          },
          {
            height: 20,
            bottom: 6,
            start: 0,
            end: 100
          }
        ]
        this.fullScreenChartConfigs[key].styles.position = function (pt) {
          return [pt[0], '5%']
        }

        this.fullScreenChartConfigs[key].styles.tooltip.formatter = (params) => {
          let tempStr1 = `<span style="display:inline-block;margin-right:4px;border-radius:10px;width:10px;height:10px;background-color:${key==='real'?this.shoreRealColor:this.shorePredictColor};"></span>`
          let tempStr2 = `<span style="display:inline-block;margin-right:4px;border-radius:10px;width:10px;height:10px;background-color:${key==='real'?this.buoyRealColor:this.buoyPredictColor};"></span>`
          let strRes =
            params[0].data.date + ' ' + params[0].axisValue + ':00' +
            '<div><div style="display:flex"><div style="margin-right:10px">' +
            tempStr1 +
            params[0].seriesName +
            '：<span style="font-weight:bold">' +
            params[0].data.value +
            '</span>m' +
            '</div></div></div>' +
            '<div><div style="display:flex"><div style="margin-right:10px">' +
            tempStr2 +
            params[1].seriesName +
            '：<span style="font-weight:bold">' +
            params[1].data +
            '</span>m' +
            '</div><div>';
          return strRes
        }
        this.fullScreenChartConfigs[key].styles.xAxis.axisLabel.interval = 'auto'

        this.fullScreenChartConfigs[key].series = JSON.parse(JSON.stringify(this.rightFirstChartSeries))

        // 中转 series
        if(key === 'real'){ // 实测数据
          let tempIndex = this.tideLevel.tidalH1[0]['DATA_TIME'].indexOf('T')
          let tempXData = []
          let seriesData1 = []
          let seriesData2 = []
          for (let i = 0; i < this.tideLevel.tidalH1.length; i++) {
            seriesData1.push({
              date: this.tideLevel.tidalH1[i]['DATA_TIME'].substring(0, 10),
              value: Number(this.tideLevel.tidalH1[i]['TIDALLEVEL']).toFixed(2)
            })
            let dateStr = this.tideLevel.tidalH1[i]['DATA_TIME'].substring(tempIndex + 1, tempIndex + 6)
            tempXData.push(dateStr)
          }
          for (let i = 0; i < this.tideLevel.tidalH2.length; i++) {
            seriesData2.push(Number(this.tideLevel.tidalH2[i]['TIDALLEVEL']).toFixed(2))

            let dateStr = this.tideLevel.tidalH1[i]['DATA_TIME'].substring(tempIndex + 1, tempIndex + 6)
            this.tideLevelTableData.push({
              index: i+1,
              date: this.tideLevel.tidalH1[i]['DATA_TIME'].substring(0, 10) + ' ' + dateStr + ':00',
              h1: Number(this.tideLevel.tidalH1[i]['TIDALLEVEL']).toFixed(2),
              h2: Number(this.tideLevel.tidalH2[i]['TIDALLEVEL']).toFixed(2),
            })
          }

          this.fullScreenChartConfigs[key].series[0].data = seriesData1
          this.fullScreenChartConfigs[key].series[0].lineStyle = {color:'#429ffe'}
          this.fullScreenChartConfigs[key].series[0].areaStyle = {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
              { offset: 0, color: '#429ffe'},
              { offset: 1, color: 'transparent' }
            ])
          }

          this.fullScreenChartConfigs[key].series[1].data = seriesData2
          this.fullScreenChartConfigs[key].series[1].lineStyle = {color:'#40c4e8'}
          this.fullScreenChartConfigs[key].series[1].areaStyle = {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
              { offset: 0, color: '#40c4e8'},
              { offset: 1, color: 'transparent' }
            ])
          }

          this.fullScreenChartConfigs[key].styles.xAxis.data = tempXData
        }else{ // 预测数据
          let tempIndex = this.tideLevel.tidalFore[0]['DATA_TIME'].indexOf('T')
          let tempXData = []
          let seriesData1 = []
          let seriesData2 = []
          for (let i = 0; i < this.tideLevel.tidalFore.length; i++) {
            seriesData1.push({
              date: this.tideLevel.tidalFore[i]['DATA_TIME'].substring(0, 10),
              value: Number(this.tideLevel.tidalFore[i]['H1']).toFixed(2)
            })
            seriesData2.push(Number(this.tideLevel.tidalFore[i]['H2']).toFixed(2))
            let dateStr = this.tideLevel.tidalFore[i]['DATA_TIME'].substring(tempIndex + 1, tempIndex + 6)
            tempXData.push(dateStr)

            this.tideLevelTableData.push({
              index: this.total/2+i+1,
              date: this.tideLevel.tidalFore[i]['DATA_TIME'].substring(0, 10) + ' ' + dateStr + ':00' + '(预报)',
              h1: Number(this.tideLevel.tidalFore[i]['H1']).toFixed(2),
              h2: Number(this.tideLevel.tidalFore[i]['H2']).toFixed(2),
            })
          }

          this.fullScreenChartConfigs[key].series[0].data = seriesData1
          this.fullScreenChartConfigs[key].series[0].lineStyle = {color:this.shorePredictColor}
          this.fullScreenChartConfigs[key].series[0].areaStyle = {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
              { offset: 0, color: this.shorePredictColor},
              { offset: 1, color: 'transparent' }
            ])
          }
          this.fullScreenChartConfigs[key].series[1].data = seriesData2
          this.fullScreenChartConfigs[key].series[1].lineStyle = { color:this.buoyPredictColor}
          this.fullScreenChartConfigs[key].series[1].areaStyle = {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
              { offset: 0, color: this.buoyPredictColor},
              { offset: 1, color: 'transparent' }
            ])
          }

          this.fullScreenChartConfigs[key].styles.xAxis.data = tempXData
          // this.fullScreenChartConfigs[key].series[0].areaStyle = null;
          const windowChartData = this.computeTideWindowChartData()
          if(windowChartData){

            // console.log(windowChartData)
            //回旋水域窗口期marker
            this.fullScreenChartConfigs[key].series[0].markArea = {
              label: {
                position: 'top',
                borderWidth: 0,
                color: 'rgba(255, 255, 225,0.5)',
                fontSize: 14,
                offset:[0,105],
                align:'left'
              },
              itemStyle: {
                color: 'rgba(46, 79, 119,0.8)'
              },
              data: windowChartData.trWater
            }
            //主航道窗口期marker
            this.fullScreenChartConfigs[key].series[1].markArea = {
              label: {
                position: 'top',
                borderWidth: 0,
                color: 'rgba(255, 255, 225,0.5)',
                offset:[0,130],
                fontSize: 14,
              },
              itemStyle: {
                // color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                //   { offset: 0, color: 'transparent' },
                //   { offset: 1, color: 'rgba(255, 173, 177, 0.4)' }
                // ])
                color: 'rgba(51, 178, 225, 0.4)'
              },
              data: windowChartData.mainChannel
            }
          }
        }

      }
      // 初始化表格当前数据
      this.handleCurrentChange(this.currentPage)
    },

    // 全屏图表开关
    firstFullScreenShow(type) {
      this.dialogVisible = true
      this.fullChartName = '潮位数据'
      // console.log('面板打开后', this.fullScreenChartConfigs);
    },

    // 翻页事件
    handleCurrentChange(index){
      // console.log("当前页码",index)
      let startIndex = this.pageSize*(index-1)
      let endIndex = this.pageSize*index
      this.currentTableData = this.tideLevelTableData.slice(startIndex,endIndex)
    },

    // 计算乘潮窗口期数据
    computeTideWindowChartData() {
      // console.log(this.tideWindows);
      // 目前只展示主航道
      if(this.tideWindows && this.tideWindows.mainChannel && this.tideWindows.mainChannel.length > 0) {
        let mainlist = this.tideWindows.mainChannel;
        let trWaterList = this.tideWindows.trWater
        let result = {mainChannel:[],trWater:[]};
        for(let i = 0; i < mainlist.length; i++) {
          console.log(mainlist[i].startTime,i)
          let tempIndex = mainlist[i].startTime.indexOf('T');
          let start = mainlist[i].startTime.substring(tempIndex + 1, tempIndex + 6)
          let end = mainlist[i].endTime.substring(tempIndex + 1, tempIndex + 6)



          // 跨天时间点会在echarts的markArea中出bug, 这里可以拆分处理
          // if(this.compareTimeStr(mainlist[i].startTime, mainlist[i].endTime)){
            let startStr = this.getTenMinInteger(start, true), endStr = this.getTenMinInteger(end, false);
            // result.push([{ name: "乘潮窗口期", xAxis: startStr }, { xAxis: '00:00' }])
            // result.push([{ name: "",xAxis: '00:00' }, { xAxis: endStr }])
            result.mainChannel.push([{ name: "主航道窗口期", xAxis: startStr }, { xAxis: endStr }])


          // }
          // }else{
          //   let startStr = this.getTenMinInteger(start, true), endStr = this.getTenMinInteger(end, false);
          //   result.mainChannel.push([{ name: "主航道窗口期", xAxis: startStr }, { xAxis: endStr }])
          // }
        }
        trWaterList.forEach((e,i)=>{
          let tempIndexTr = e.startTime.indexOf('T');
          let startTr = e.startTime.substring(tempIndexTr + 1, tempIndexTr + 6)
          let endTr = e.endTime.substring(tempIndexTr + 1, tempIndexTr + 6)

          let startStrTr = this.getTenMinInteger(startTr, true), endStrTr = this.getTenMinInteger(endTr, false);
          result.trWater.push([{  name: "回旋水域窗口期",xAxis: startStrTr }, { xAxis: endStrTr }])
        })

        return result;
      }
    },
    // 比较时间：hh:mm 的大小, true则后者大
    compareTimeStr(str1, str2) {
      let date1 = new Date(`${str1.substring(0, 10)} ${str1.substring(11, 19)}`).getDate();
      let date2 = new Date(`${str2.substring(0, 10)} ${str2.substring(11, 19)}`).getDate();
      // console.log(date1, date2);
      return date1 < date2
    },
    // 10分钟内的数据取整
    getTenMinInteger(timeStr, isStart){
      let step = 10 * 60 * 1000;
      let tempDate = new Date('2021-12-12 ' + timeStr); // 这里取任意日期时间做中转
      let targetDate = isStart ? new Date(Math.ceil(tempDate / step) * step) : new Date(Math.floor(tempDate / step) * step);
      let hour = targetDate.getHours() >= 10 ? targetDate.getHours() : '0' + targetDate.getHours();
      let minute = targetDate.getMinutes() < 10 ?  targetDate.getMinutes() + '0' : targetDate.getMinutes();
      return hour + ':' + minute;
    },
    // 清空图表信息
    clearFullScreenData() {
      // this.fullScreenChartStyles = {}
      // this.fullScreenChartSeries = []
    },
    // 日期下拉选项
    openOption() {
      this.isTideLevelDateSign = true
    },
    hideOption() {
      if (this.isTideLevelDateSign) {
        this.isTideLevelDateSign = false
      }
    },
    // 切换展示数据表格
    toggleDataTable() {
      this.isShowDataTable = !this.isShowDataTable
    }
  },
  beforeDestroy(){
    clearInterval(this.tideTimer)
  },
}
</script>

<style lang="scss" scoped>
.container {
  position: relative;
}
.btn_site {
  &:hover .tideLevelOptions {
    display: block;
  }
  // &::after {
  //   content: '.';
  //   height: 0;
  //   display: block;
  //   clear: both;
  // }

  .tideLevelSelectd {
    color: rgba(255, 255, 255, 0.9);
  }
  .tideLevelOptions {
    display: none;
    position: absolute;
    top: 18px;
    left: 0px;
    box-sizing: border-box;
    // border: 1px solid #687188;
    padding: 5px 0;
    z-index: 9999;
    p {
      color: rgba(255, 255, 255, 0.5);
      padding: 0 5px;
      height: 18px;
      cursor: pointer;
      &:hover {
        background-color: #4e5f77;
      }
    }
    .active {
      color: rgba(255, 255, 255, 0.9);
    }
  }
}

.full_screen {
  padding-right: 5px;
  .dialog_title {
    width: 100%;
    box-sizing: border-box;
    margin-bottom: 10px;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #687188;
    background-image: linear-gradient(#fff, #73abff);
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
    font-size: 18px;
    font-family: YouSheBiaoTiHei;
    .title_left {
      img {
        width: 16px;
        height: 16px;
        margin-right: 2px;
        margin-bottom: 3px;
      }
    }
  }
  .content {
    position: relative;
  }
  .charts_block{
    width: 100%;
    height: 260px;
    margin-bottom: 20px;
    .charts_title{
      font-size: 15px;
      color: #fff;
      float: right;
      margin-right: 20px;
    }
  }
  .show_data_btn{
    height: 20px;
    border-radius: 4px;
    background-color: #2196f3;
    margin: 10px 16px;
    text-align: center;
    font-size: 12px;
    color: #ffffff;
    padding-top: 2px;
    cursor: pointer;
  }
  .data_content{
    position: relative;
    padding: 0 16px 16px 16px;
    .header {
      display: flex;
      background-image: linear-gradient(
        rgba(144, 212, 255, 0.0),
        rgba(144, 212, 255, 0.4)
      );
      span {
        flex: 1;
        height: 20px;
        line-height: 20px;
        font-size: 12px;
        color: #53c8ff;
        text-align: center;

      }
    }
    .items_scroll{
      overflow-y: scroll;
      // height: 190px;
      height: 500px;
      .items {
        height: 20px;
        line-height: 20px;
        color: #d5e7ff;
        text-align: center;
        border-bottom: 1px solid rgba(255, 255, 255, 0.18);
        display: flex;
        position: relative;
        font-size: 12px;
        > .item {
          flex: 1;
          user-select: auto;
        }
        &:hover{
          background: rgba(144, 212, 255, 0.2);
        }
      }
    }
  }
}
.fold_active {
  transition: all 0.3s;
  transform: rotateX(180deg);
}
</style>
