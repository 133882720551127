<template>
  <div class="title">
    <div class="title_left">
      <img :src="iconImg" alt />
      <span>{{ name }}</span>
    </div>
    <div>
      <img src="@/assets/images/overview/title.png" alt />
    </div>
  </div>
</template>

<script>
export default {
  name: 'Subtitle',

  props: {
    iconImg: { type: String, required: true },
    name: { type: String, required: true }
  },

  data() {
    return {}
  },
  mounted() {},
  // 组件方法
  methods: {}
}
</script>

<style lang="scss" scoped>
.title {
  position: relative;
  width: 330px;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #687188;
  background-image: linear-gradient(#fff, #73abff);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  font-size: 18px;
  font-family: YouSheBiaoTiHei;
  .title_left {
    margin-left: -2px;
    img {
      width: 30px;
      height: 30px;
      // margin-bottom: 5px;
    }
  }
  &::before {
    content: '';
    position: absolute;
    bottom: -1px;
    left: 0;
    width: 4px;
    border-bottom: 1px solid #fff;
  }
}
</style>
