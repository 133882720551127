<template>
  <div class="container">
    <subtitle :iconImg="iconImg" name="设备设施" />
    <div class="first_content">
      <div class="first_item">
        <div class="item_img">
          <img src="@/assets/images/overview/1-1.png" alt />
          <div class="item_bg">
            <div class="item_arrow_left"></div>
            <div class="item_arrow_right"></div>
          </div>
        </div>
        <div class="item_txt">
          <p>浮标</p>
          <p class="item_num">{{ deviceData.buoy }}</p>
        </div>
      </div>
      <div class="first_item">
        <div class="item_img">
          <img src="@/assets/images/overview/1-2.png" alt />
          <div class="item_bg">
            <div class="item_arrow_left"></div>
            <div class="item_arrow_right"></div>
          </div>
        </div>
        <div class="item_txt">
          <p>岸标</p>
          <p class="item_num">{{ deviceData.shoreBeacon }}</p>
        </div>
      </div>
      <div class="first_item">
        <div class="item_img">
          <img src="@/assets/images/overview/1-3.png" alt />
          <div class="item_bg">
            <div class="item_arrow_left"></div>
            <div class="item_arrow_right"></div>
          </div>
        </div>
        <div class="item_txt">
          <p>灯塔</p>
          <p class="item_num">{{ deviceData.lighthouse }}</p>
        </div>
      </div>
      <div class="first_item">
        <div class="item_img">
          <img src="@/assets/images/overview/1-4.png" alt />
          <div class="item_bg">
            <div class="item_arrow_left"></div>
            <div class="item_arrow_right"></div>
          </div>
        </div>
        <div class="item_txt">
          <p>摄像头</p>
          <p class="item_num">{{ deviceData.camera }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import subtitle from './subtitle.vue'
import request from '../../../utils/request-common'
export default {
  name: 'LeftFirst',
  components: { subtitle },
  data () {
    return {
      // 标题图片
      iconImg: require('@/assets/images/overview/1.png'),

      isLoadedData: false,
      deviceData: {
        buoy: 27,
        shoreBeacon: 10,
        lighthouse: 1,
        camera: 20
      }
    }
  },
  created () {
    this.getDeviceData()
  },
  methods: {
    // 获取潮位数据
    async getDeviceData () {
      try {
        if (!this.isLoadedData) {
          const { data } = await request('fn/deviceStat', {})
          let result = data.data;
          this.deviceData.buoy = result.deviceData.buoy;
          this.deviceData.shoreBeacon = result.deviceData.shore_beacon;
          this.deviceData.beacon = result.deviceData.lighthouse;
          this.deviceData.camera = result.deviceData.camera;
        }
      } catch (err) {
        console.log('获取数据失败！', err)
      }
    },
  }
}
</script>

<style lang="scss" scoped>
.container {
  position: relative;
  font-family: SiYuan;
}

.first_content {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  // font-weight: bold;
  font-size: 14px;

  .first_item {
    width: 160px;
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;

    .item_img {
      position: relative;
      margin-right: 18px;
      width: 40px;
      height: 40px;

      // &::after {
      //   position: absolute;
      //   content: '';
      //   top: 0;
      //   left: 0;
      //   width: 40px;
      //   height: 40px;
      //   box-sizing: border-box;
      //   border-radius: 50%;
      //   border: 1.5px solid #03bde725;
      //   box-shadow: 0 0 2px 2px #0ac0e9;
      //   animation: circle_ripple 3s infinite;
      // }
      img {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }

      .item_bg {
        position: relative;
        box-sizing: border-box;
        width: 40px;
        height: 40px;
        border: 1px solid #03bde7;
        display: flex;
        border-radius: 50%;
        box-shadow: 0 0 5px 5px #0ac0e963 inset;
        animation: circle_rotate 5s infinite;
        animation-timing-function: linear;

        &::before {
          position: absolute;
          content: '';
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          width: 50px;
          height: 50px;
          border-radius: 50%;
          box-shadow: 0 0 5px 5px #0ac0e931 inset;
          border: 1.5px solid #03bde78c;
        }

        .item_arrow_left {
          position: absolute;
          top: 55%;
          left: -5px;
          transform-origin: center;
          box-sizing: border-box;
          width: 7px;
          height: 7px;
          border-top: 2px solid #03e7e7;
          border-right: 2px solid #03e7e7;
          transform: rotate(45deg) translate(-50%, -50%);

          &::after {
            position: absolute;
            content: '';
            top: -2px;
            left: 0px;
            transform-origin: center;
            box-sizing: border-box;
            border-top: 5px solid transparent;
            border-left: 5px solid #03e7e7;
            border-bottom: 5px solid transparent;
            transform: rotate(-45deg);
          }
        }

        .item_arrow_right {
          position: absolute;
          top: 55%;
          right: -5px;
          transform-origin: center;
          box-sizing: border-box;
          width: 7px;
          height: 7px;
          border-bottom: 2px solid #03e7e7;
          border-left: 2px solid #03e7e7;
          transform: rotate(45deg) translate(-50%, -50%);

          &::after {
            position: absolute;
            content: '';
            bottom: -2px;
            right: -1px;
            transform-origin: center;
            box-sizing: border-box;
            border-top: 5px solid transparent;
            border-right: 5px solid #03e7e7;
            border-bottom: 5px solid transparent;
            transform: rotate(-45deg);
          }
        }
      }
    }

    .item_txt {
      margin: 5px 0 0 10px;
      color: #a7eaf5;
      font-size: 14px;

      p:first-child {
        // font-weight: bold;
      }

      .item_num {
        padding-top: 5px;
        font-size: 14px;
        color: #fff;
        font-weight: bold;

        span {
          display: inline-block;
          color: #00cefa;
          transform: scale(0.5) translate(-50%, 30%);
        }
      }
    }
  }
}
</style>
