<template>
  <div class="container">
    <subtitle :iconImg="iconImg" name="监控视频" />
    <div class="third_content">
      <div v-for="(item,index) in playerList"  :key="index" class="third_item">
        <div class="small_title">
          <span>{{item.camera}}</span>
          <span>{{currentDate}}</span>
        </div>
        <video-player
          class="video-player"
          :playerID="item.id"
          :url="item.url"
          :poster="item.poster"
          :options="videoOptions"/>
      </div>
      <!-- 劫持button,修改绑定事件 -->
      <div v-for="(item,index) in cameraList"  :key="index" class="third_item">
        <div class="small_title">
          <span>{{item.camera}}</span>
          <span>{{currentDate}}</span>
        </div>
        <el-button class="camera_button" @click="openNewTab(item.id)">
          <div class="triangle"></div>
        </el-button>
        <div class="vjs-poster" aria-disabled="false">
          <picture class="vjs-poster" tabindex="-1">
            <img loading="lazy" :src="item.poster">
          </picture>
        </div>
      </div>
    </div>
    <!-- 可见光摄像头弹窗 -->
<!--    <el-dialog id="visibleField" ref="visibleField" :visible.sync="isVisibleDialog" :append-to-body="true" width="80%"-->
<!--      @close='visibleDialogBth("closeDialog")' top="0" :show-close="false" center>-->
<!--      <div class="el-icon-full-screen dialogBth" @click='visibleDialogBth("fullDialog")'></div>-->
<!--      <div class="el-icon-close dialogBth" @click='visibleDialogBth("closeDialog")'></div>-->
<!--      <iframe src="http://39.105.44.210:9101/" frameborder="0" name="visibleField"-->
<!--        width="100%" height="100%"></iframe>-->
<!--    </el-dialog>-->
    <!-- 热红外摄像头弹窗 -->
<!--    <el-dialog id="infraredField" ref="infraredField" :visible.sync="isInfraredDialog" :append-to-body="true" width="80%"-->
<!--      @close='infraredDialogBth("closeDialog")' top="0" :show-close="false" center>-->
<!--      <div class="el-icon-full-screen dialogBth" @click='infraredDialogBth("fullDialog")'></div>-->
<!--      <div class="el-icon-close dialogBth" @click='infraredDialogBth("closeDialog")'></div>-->
<!--      <iframe src="http://39.105.44.210:9102/" frameborder="0" name="infraredField"-->
<!--        width="100%" height="100%"></iframe>-->
<!--    </el-dialog>-->
  </div>
</template>

<script>
import subtitle from './subtitle.vue'
import VideoPlayer from '@/components/videoPlayer/VideoPlayer'

export default {
  name: 'LeftSecond',
  components: {
    subtitle,
    VideoPlayer
  },
  data() {
    return {
      // 标题图片
      iconImg: require('@/assets/images/overview/3.png'),
      video1: require('@/assets/images/videoPoster/video1.png'),
      publicPath: process.env.BASE_URL,
      // 港区监控摄像头
      playerList: [],

      // 铁塔AI摄像头
      isVisibleDialog: false,
      isInfraredDialog: false,
      cameraList: [{
        id: `visible`,
        camera: `可见光`,
        poster: require(`@/assets/images/videoPoster/visible.png`),//视频封面
      },{
        id: `infrared`,
        camera: `热红外`,
        poster: require(`@/assets/images/videoPoster/infrared.png`),//视频封面
      }],
      videoOptions: {}
    }
  },
  created(){
    for(let i=1;i<=8;i++){
      this.playerList.push({
        id: `my-video${i}`,
        camera: `${i}号高杆灯`,
        poster: require(`@/assets/images/videoPoster/video${i}.png`),//视频封面
        url: `http://39.105.44.210:9000/live${i}/index.m3u8`
      })
    }
  },
  computed: {
    // 返回当前的日期
    currentDate(){
      const date = new Date()
      const year = date.getFullYear()
      const month = String(date.getMonth()+1).padStart(2,'0')
      const day = String(date.getDate()).padStart(2,'0')
      return `${year}-${month}-${day}`
    }
  },
  methods:{
    // 点击跳转的新的标签页
    openNewTab(id){
      if(id === "visible"){
        window.open("http://120.211.0.72:801/","_blank")
      }else{
        window.open("http://120.211.0.72:802/","_blank")
      }
    },
    // 打开对话框
    openDialog(id){
      if(id === "visible"){
        this.isVisibleDialog = true
      }else{
        this.isInfraredDialog = true
      }
    },
    // 可见光摄像头窗口操作
    visibleDialogBth (action) {
      if (action === 'openDialog') {
        this.isVisibleDialog = true
        return
      } else if (action === 'closeDialog') {
        this.isVisibleDialog = false
      }
      const dialogDom = this.$refs['visibleField'].$el.childNodes[0]
      if (action === 'fullDialog' && dialogDom.className.indexOf('full_screen') > 0 || action === 'closeDialog') {
        dialogDom.className = dialogDom.className.replace(' full_screen', '')
      } else {
        dialogDom.className += ' full_screen'
      }
    },
    // 热红外摄像头窗口操作
    infraredDialogBth (action) {
      if (action === 'openDialog') {
        this.isVisibleDialog = true
        return
      } else if (action === 'closeDialog') {
        this.isVisibleDialog = false
      }
      const dialogDom = this.$refs['infraredField'].$el.childNodes[0]
      if (action === 'fullDialog' && dialogDom.className.indexOf('full_screen') > 0 || action === 'closeDialog') {
        dialogDom.className = dialogDom.className.replace(' full_screen', '')
      } else {
        dialogDom.className += ' full_screen'
      }
    },
  },
  mounted(){
  }
}
</script>

<style lang="scss" scoped>
.container {
  position: relative;
  font-family: SiYuan;
}
.third_content {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  padding-top: 20px;
  .third_item {
    position: relative;
    width: 158px;
    height: 105px;
    box-sizing: border-box;
    background-color: #000;
    margin-bottom: 10px;
    text-align: center;
    // padding-top: 15px;
    .small_title {
      position: absolute;
      top: 7px;
      padding: 0 3px;
      box-sizing: border-box;
      width: 100%;
      display: flex;
      font-size: 12px;
      font-weight: bold;
      justify-content: space-between;
      color: #135477;
      z-index: 999;
    }
    video {
      width: 100%;
      height: 100%;
    }

    .camera_button {
      width: 58px;
      height: 30px;
      background-color: rgba(43,51,63,0.7);
      background-clip: border-box;
      border-radius: 6px;
      margin-top: 35px;
      margin-left: 0px;
      position: relative;
      z-index: 999;
      /* 解决video元素的button无法交互的问题 */
      pointer-events: auto;
      .triangle {
          width: 0;
          height: 0;
          border-style: solid;
          border-color: transparent;
          border-width: 6px 0 6px 9px;
          border-left-color: white;
          margin-left: 4px;
          margin-top: -4px;
      }
    }
    .camera_button:hover {
      background-color: rgba(112,112,112,0.7);
    }
  }
}

#visibleField{
  display: flex;
  justify-content: center;
  align-items: center;

  ::v-deep .el-dialog {
    background-color: #0a3263;
    margin: 0;
    top: 0;
    height: 80vh;

    .el-dialog__header {
      padding: 0;
    }

    .el-dialog__body {
      padding: 0;
      height: 100%;
    }
  }

  ::v-deep .full_screen {
    width: 100% !important;
    height: 100% !important;
    overflow: hidden !important;
  }

  .el-icon-full-screen {
    right: 50px;
    font-size: 18px;
  }
  .el-icon-close {
    right: 20px;
    font-size: 20px;
  }
}

#infraredField{
  display: flex;
  justify-content: center;
  align-items: center;

  ::v-deep .el-dialog {
    background-color: #0a3263;
    margin: 0;
    top: 0;
    height: 80vh;

    .el-dialog__header {
      padding: 0;
    }

    .el-dialog__body {
      padding: 0;
      height: 100%;
    }
  }

  ::v-deep .full_screen {
    width: 100% !important;
    height: 100% !important;
    overflow: hidden !important;
  }

  .el-icon-full-screen {
    right: 50px;
    font-size: 18px;
  }
  .el-icon-close {
    right: 20px;
    font-size: 20px;
  }
}

.dialogBth {
  position: absolute;
  top: 10px;
  right: 20px;
  font-size: 20px;
  color: #909399;
  cursor: pointer;

  &:hover {
    color: #409eff;
  }
}
</style>
