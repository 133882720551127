<template>
  <div class="container">
    <subtitle :iconImg="iconImg" name="能见度数据" />
    <div class="btn_group">
      <div class="btn_screen" @click="fourFullScreenShow">详细数据</div>
      <!-- <div class="btn_site">观测站{{ visibilitySiteValue }}</div> -->
      <div class="btn_site">气象站</div>
      <div class="date_str">{{ dateStr }}</div>
    </div>
    <div style="width: 340px; height: 200px">
      <basic-chart :styles-data="rightFourthChartStyles" :series-data="rightFourthChartSeries" />
    </div>

    <el-dialog
      :show-close="false"
      :visible.sync="dialogVisible"
      :destroy-on-close="true"
      :append-to-body="true"
      width="60%"
      top="10px"
      class="full_screen"
      @close="clearFullScreenData"
    >
      <div>
        <div class="dialog_title">
          <div class="title_left">
            <img src="../../../assets/images/overview/curve.png" alt />
            <span>{{ fullChartName }}</span>
            <span>-详细数据</span>
          </div>
          <div>
            <img src="../../../assets/images/overview/title.png" alt />
          </div>
        </div>
        <div class="content">
          <div style="width: 100%; height: 380px">
            <basic-chart v-if="dialogVisible" :styles-data="fullScreenChartStyles" :series-data="fullScreenChartSeries" />
          </div>
        </div>
        <div class="show_data_btn" @click="toggleDataTable">查看数据表格<span>{{isShowDataTable ? '▼' : '▲'}}</span></div>
        <div class="data_content" v-show="isShowDataTable">
          <div class="header">
            <span>序号</span>
            <span>日期时间</span>
            <span>10min平均能见度(m)</span>
          </div>
          <div class="items_scroll">
            <div class="items-warp">
              <div class="items" v-for="(e,i) in currentTableData" :key="i">
                <span class="item">{{e.index}}</span>
                <span class="item">{{e.date}}</span>
                <span class="item">{{e.avg10m}}</span>
              </div> 
            </div>
          </div>
        </div>
        <el-pagination
          class="pagination"
          layout="prev, pager, next"
          :total="total"
          :page-size="pageSize"
          :current-page="currentPage"
          :pager-count="pageCount"
          @current-change="handleCurrentChange"
        >
        </el-pagination>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import * as echarts from 'echarts'
import basicChart from '@/components/echarts/basic-chart.vue'
import subtitle from './subtitle.vue'
import request from '../../../utils/request'
import { default as mapApp } from '@/map/init.js'
export default {
  name: 'RightFourth',
  components: { basicChart, subtitle },
  data() {
    return {
      isShowDataTable: true,
      // 标题图片
      iconImg: require('@/assets/images/overview/能见度.png'),

      // 能见度数据
      visibility: null,
      visibilitySite: [
        {
          value: 31,
          label: '气象站'
        }
      ],
      rightFourthChartStyles: {
        grid: {
          left: '0%',
          right: '5%',
          top: '15%',
          bottom: '0',
          containLabel: true
        },
        tooltip: {
          trigger: 'axis',
          confine: true,
          formatter: (params) => {
            let tempStr1 = `<span style="display:inline-block;margin-right:4px;border-radius:10px;width:10px;height:10px;background-color:#4ddefd;"></span>`
            let strRes =
              params[0].data.date + ' ' + params[0].axisValue + ':00' +
              '<div style="display:grid;grid-template-columns:40% 60%;justify-items:start"><div style="margin-right:10px"><b>' +
              tempStr1 +
              params[0].data.value +
              '</b></div><div>' +
              params[0].seriesName +
              '</div></div>'
            return strRes
          }
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: [],
          axisLabel: {
            color: '#fff',
            fontSize: 10
          }
        },
        yAxis: {
          type: 'value',
          name: 'km',
          nameTextStyle: {
            color: '#ffffff',
            align: 'right',
            fontSize: 10
          },
          type: 'value',
          min: 0,
          max: 20,
          interval: 2,
          axisLabel: {
            color: '#fff'
          },
          splitLine: {
            show: true,
            lineStyle: {
              type: 'dashed',
              color: '#29506C',
              opacity: 0.5
            }
          }
        }
      },
      rightFourthChartSeries: [
        {
          name: '十分钟平均值km',
          data: [],
          type: 'line',
          smooth: true,
          showSymbol: false,
          lineStyle: {
            color: '#4ddefd',
            width: 2
          },
          areaStyle: {
            //颜色渐变函数 前四个参数分别表示四个位置依次为左、下、右、上
            color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
              { offset: 0, color: '#4ddefd' },
              { offset: 1, color: 'transparent' }
            ])
          }
        }
      ],
      visibilitySiteValue: 31,
      visibilityDate: [],
      visibilityDateValue: '',

      fullChartName: '',
      fullScreenChartStyles: null,
      fullScreenChartSeries: null,

      visibilityTableData: [],

      dateStr: '',

      // 对话框开关
      dialogVisible: false,

      // 分页功能
      currentTableData: [],
      currentPage: 6,
      total: 144,
      pageSize: 24,
      // 最大页码按钮数量, 必须是大于等于 5 且小于等于 21 的奇数
      pageCount: 7,
    }
  },
  created() {
    this.getVisibilityData()
    this.visiTimer = setInterval(()=>{
      this.getVisibilityData()
    },60*2*1000)
  },

  methods: {
    // 能见度相关
    async getVisibilityData() {
      try {
        const { data } = await request('fn/visi', 24)
        this.visibility = data.data.visibility
        this.visibilityChartDataSet()
        this.updateForthFullScreenData()
        this.setDateDateExtentStr()
      } catch (err) {
        console.log('获取数据失败！', err)
      }
    },
    // 设置数据时间范围
    setDateDateExtentStr() {
      this.dateStr = this.getDateStr(-1) + ' 至 ' + this.getDateStr(0)
    },
    // 根据日期偏移或者时间字符串
    getDateStr(dateOffset) {
      let currDate = new Date();
      if(dateOffset) {
        currDate.setDate(currDate.getDate() + dateOffset);
        return `${currDate.getFullYear()}-${currDate.getMonth() + 1}-${currDate.getDate()}`
      }else{
        return `${currDate.getFullYear()}-${currDate.getMonth() + 1}-${currDate.getDate()}`
      }
    },
    visibilityChartDataSet() {
      if (this.visibility) {
        let seriesData1 = []
        // x轴坐标值
        let tempXData = []
        let tempIndex = this.visibility[0]['DATA_TIME'].indexOf('T')
        for (let i = 0; i < this.visibility.length; i++) {
          if ( i % 6 === 5) {
            // seriesData1.push(Number(this.visibility[i]['AVG_10M']).toFixed(2))
            seriesData1.push({
              date: this.visibility[i]['DATA_TIME'].substring(0, 10),
              value: Number(this.visibility[i]['AVG_10M']).toFixed(2)
            })
            // x轴
            let dateStr = this.visibility[i]['DATA_TIME'].substring(tempIndex + 1, tempIndex + 6)
            tempXData.push(dateStr)
          }
        }
        this.rightFourthChartSeries[0].data = seriesData1
        this.rightFourthChartStyles.xAxis.data = tempXData

        // 设置观测设备弹窗数据
        this.setDataDevicePopData(this.getCurrNearTimeData());
      }
    },

    // 获取离当前时间点最接近的数据
    getCurrNearTimeData() {
      let currDate = new Date();
      let timeIndex = 0;
      let tempMin = null;
      for (let i=0, len=this.visibility.length; i < len; i++) {
        let timeStr = this.visibility[i]['DATA_TIME'];
        let dateStr = `${timeStr.substring(0, 10)} ${timeStr.substring(11, 16)}`;
        let targetDate = new Date(dateStr);
        let minute = parseInt(Math.abs(currDate - targetDate) / 1000 / 60);
        if(!tempMin) tempMin = minute;
        if(minute < tempMin){
          timeIndex = i;
          tempMin = minute;
        }
      }
      return this.visibility[timeIndex]
    },

    // 设置数据设施弹窗数据
    setDataDevicePopData(data) {
      mapApp.dataDevicePopData.weatherData.averageVisibility = Number(data['AVG_10M']).toFixed(2)
      // 缓存数据
      mapApp.storeDataDevicePopData();
    },
    
    // 更新全屏图表数据
    updateForthFullScreenData(){
      this.visibilityTableData.length = 0;
      // 中转 styles
      this.fullScreenChartStyles = JSON.parse(JSON.stringify(this.rightFourthChartStyles))
      this.fullScreenChartStyles.grid = {
        left: '2%',
        right: '2%',
        top: '10%',
        bottom: '10%',
        containLabel: true
      }
      this.fullScreenChartStyles.dataZoom = [
        {
          type: 'inside',
          start: 0,
          end: 100
        },
        {
          height: 20,
          bottom: 6,
          start: 0,
          end: 100
        }
      ]
      this.fullScreenChartStyles.position = function (pt) {
        return [pt[0], '5%']
      }
      // 中转 series
      this.fullScreenChartSeries = JSON.parse(JSON.stringify(this.rightFourthChartSeries))
      // series[0]
      let tempDataAry1 = []
      let tempXData = []
      for (let i = 0; i < this.visibility.length; i++) {
        let tempIndex = this.visibility[i]['DATA_TIME'].indexOf('T')
        let dateStr = this.visibility[i]['DATA_TIME'].substring(tempIndex + 1, tempIndex + 6)

        tempXData.push(dateStr)
        tempDataAry1.push({
          date: this.visibility[i]['DATA_TIME'].substring(0, 10),
          value: Number(this.visibility[i]['AVG_10M']).toFixed(2)
        })

        this.visibilityTableData.push({
          index: i+1,
          date: this.visibility[i]['DATA_TIME'].substring(0, 10) + ' ' + dateStr + ':00',
          avg10m: Number(this.visibility[i]['AVG_10M']).toFixed(2),
        })
      }
      this.fullScreenChartStyles.xAxis.data = tempXData
      this.fullScreenChartStyles.tooltip.formatter = (params) => {
        let tempStr1 = `<span style="display:inline-block;margin-right:4px;border-radius:10px;width:10px;height:10px;background-color:#4ddefd;"></span>`
        let strRes =
          params[0].data.date + ' ' + params[0].axisValue + ':00' +
          '<div style="display:grid;grid-template-columns:40% 60%;justify-items:start"><div style="margin-right:10px"><b>' +
          tempStr1 +
          params[0].data.value +
          '</b></div><div>' +
          params[0].seriesName +
          '</div></div>'
        return strRes
      }
      this.fullScreenChartSeries[0].data = tempDataAry1

      // 初始化表格当前数据
      this.handleCurrentChange(this.currentPage)
    },

    // 全屏图表开关
    fourFullScreenShow() {
      this.dialogVisible = true
      this.fullChartName = '能见度数据'
      // console.log("详细图表数据4:",this.fullScreenChartStyles)
    },

    // 翻页事件
    handleCurrentChange(index){
      // console.log("当前页码",index)
      let startIndex = this.pageSize*(index-1)
      let endIndex = this.pageSize*index
      this.currentTableData = this.visibilityTableData.slice(startIndex,endIndex)
    },

    // 清除全屏图表数据
    clearFullScreenData() {
      // 注释掉，关闭开关不再清除数据
      // this.fullScreenChartStyles = {}
      // this.fullScreenChartSeries = []
    },
    // 切换展示数据表格
    toggleDataTable() {
      this.isShowDataTable = !this.isShowDataTable 
    },
  },
  beforeDestroy(){
    clearInterval(this.visiTimer)
  }
}
</script>

<style lang="scss" scoped>
.container {
  position: relative;
}
.full_screen {
  .dialog_title {
    width: 100%;
    box-sizing: border-box;
    margin-bottom: 10px;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #687188;
    background-image: linear-gradient(#fff, #73abff);
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
    font-size: 18px;
    font-family: YouSheBiaoTiHei;
    .title_left {
      img {
        width: 16px;
        height: 16px;
        margin-right: 2px;
        margin-bottom: 3px;
      }
    }
  }
  .show_data_btn{
    height: 20px;
    border-radius: 4px;
    background-color: #2196f3;
    margin: 10px 16px;
    text-align: center;
    font-size: 12px;
    color: #ffffff;
    padding-top: 2px;
    cursor: pointer;
  }
  .data_content{
    position: relative;
    padding: 0 16px 16px 16px;
    .header {
      display: flex;
      background-image: linear-gradient(
        rgba(144, 212, 255, 0.0),
        rgba(144, 212, 255, 0.4)
      );
      span {
        flex: 1;
        height: 20px;
        line-height: 20px;
        font-size: 12px;
        color: #53c8ff;
        text-align: center;
        
      }
    }
    .items_scroll{
      overflow-y: scroll;
      height: 500px;
      .items {
        height: 20px;
        line-height: 20px;
        color: #d5e7ff;
        text-align: center;
        border-bottom: 1px solid rgba(255, 255, 255, 0.18);
        display: flex;
        position: relative;
        font-size: 12px;
        > .item {
          flex: 1;
          user-select: auto;
        }
        &:hover{
          background: rgba(144, 212, 255, 0.2);
        }
      }
    }
  }
}
.btn_group {
  width: 330px;
}
</style>
