<template>
  <div class="pop_container" :style="{ transform: popTips.transformStyle }" v-show="popTips.visible">
    <div class="pop_main">
      <div class="pop_box">唐山丰南纵横码头</div>
      <div class="pop_arrow"><div></div></div>
    </div>
  </div>
</template>

<script>
import { default as mapApp } from '@/map/init.js'
export default {
  name: 'HomePop',
  components: {},
  data() {
    return {
      popTips: {
        x: null,
        y: null,
        floorNum: 1,
        offsetHeight: 0,
        transformStyle: '',
        visible: false
      }
    }
  },
  computed: {},
  watch: {},
  created() {},
  mounted() {
    mapApp.overviewMapActions.homePopVueComponent = this
  }
}
</script>

<style lang="scss" scoped>
.pop_container {
  position: absolute;
  .pop_main {
    position: relative;
    top: -20px;
    .pop_box {
      width: 240px;
      height: 56px;
      box-sizing: border-box;
      font-size: 24px;
      text-align: center;
      line-height: 56px;
      font-weight: 900;
      letter-spacing: 2px;
      color: #fff;
      border-left: 6px solid #0fa3ff;
      background-image: linear-gradient(to right, #62bff8, rgba(0, 0, 0, 0));
    }
    .pop_arrow {
      position: relative;
      display: inline-block;
      top: 20px;
      width: 100%;
      height: 28px;
      background-image: url('../../../assets/images/overview/pop_arrow.png');
      background-position: 50%;
      background-repeat: no-repeat;
      text-align: center;
      animation-name: bounce-bottom;
      animation-duration: 0.8s;
      animation-timing-function: ease;
      animation-iteration-count: infinite;
      animation-direction: alternate;
      > div {
      }
    }
  }
}
@keyframes bounce-bottom {
  0% {
    transform: translateY(0px);
  }
  100% {
    transform: translateY(8px);
  }
}
</style>
