<template>
  <div class="container">
    <subtitle :iconImg="iconImg" name="潮流数据" />
    <div class="btn_group">
      <div class="btn_screen" @click="secondFullScreenShow(tideVelocitySiteValue)">详细数据</div>
      <!-- <div class="btn_site">观测站{{ tideVelocitySiteValue }}</div> -->
      <div class="btn_site">浮标站</div>
      <div class="date_str">{{ dateStr }}</div>
    </div>
    <div style="width: 340px; height: 200px">
      <basic-chart :styles-data="rightSecondChartStyles" :series-data="rightSecondChartSeries" />
    </div>

    <el-dialog
      :show-close="false"
      :visible.sync="dialogVisible"
      :destroy-on-close="true"
      :append-to-body="true"
      width="60%"
      top="10px"
      class="full_screen"
      @close="clearFullScreenData"
    >
      <div>
        <div class="dialog_title">
          <div class="title_left">
            <img src="../../../assets/images/overview/curve.png" alt />
            <span>{{ fullChartName }}</span>
            <span>-详细数据</span>
          </div>
          <div>
            <img src="../../../assets/images/overview/title.png" alt />
          </div>
        </div>
        <div class="content">
          <div style="width: 100%; height: 380px">
            <basic-chart v-if="dialogVisible" :styles-data="fullScreenChartStyles" :series-data="fullScreenChartSeries" />
          </div>
        </div>
        <div class="show_data_btn" @click="toggleDataTable">查看数据表格<span>{{isShowDataTable ? '▼' : '▲'}}</span></div>
        <div class="data_content" v-show="isShowDataTable">
          <div class="header">
            <span>序号</span>
            <span>日期时间</span>
            <span>流速(m/s)</span>
            <span>流向(°)</span>
          </div>
          <div class="items_scroll">
            <div class="items-warp">
              <div class="items" v-for="(e,i) in currentTableData" :key="i">
                <span class="item">{{e.index}}</span>
                <span class="item">{{e.date}}</span>
                <span class="item">{{e.velocity}}</span>
                <span class="item">{{e.angle}}</span>
              </div> 
            </div>
          </div>
        </div>
        <el-pagination
          class="pagination"
          layout="prev, pager, next"
          :total="total"
          :page-size="pageSize"
          :current-page="currentPage"
          :pager-count="pageCount"
          @current-change="handleCurrentChange"
        >
        </el-pagination>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import * as echarts from 'echarts'
import basicChart from '@/components/echarts/basic-chart.vue'
import subtitle from './subtitle.vue'
import request from '../../../utils/request'
export default {
  name: 'RightSecond',
  components: { basicChart, subtitle },
  data() {
    return {
      // 潮流表格
      isShowDataTable: true,

      // 标题图片
      iconImg: require('@/assets/images/overview/潮流.png'),
      // 潮流数据
      tideVelocity: null,
      tideVelocitySite: [
        {
          value: 1,
          label: '浮标站'
        }
      ],
      rightSecondChartStyles: {
        grid: {
          left: '0',
          right: '5%',
          top: '15%',
          bottom: '0',
          containLabel: true
        },
        tooltip: {
          trigger: 'axis',
          formatter: (params) => {
            let tempStr1 = `<span style="display:inline-block;margin-right:4px;border-radius:10px;width:10px;height:10px;background-color:#66B2FF"></span>`
            let dfStr = params[1].data ? '°' : ''
            let degreeStr = (params[1].data || 0) + dfStr
            let strRes =
              params[0].data.date + ' ' + params[0].axisValue + ':00' +
              '<div><div style="display:flex"><div style="margin-right:10px">' +
              tempStr1 +
              params[0].seriesName +
              '：<span style="font-weight:bold">' +
              params[0].data.value +
              '</span>m/s' +
              '</div><div>' +
              params[1].seriesName +
              '：<span style="font-weight:bold">' +
              degreeStr +
              '</span>' +
              '</div></div></div>'
            return strRes
          }
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: [],
          axisLabel: {
            color: '#fff',
            fontSize: 10
          }
        },
        yAxis: [
          {
            type: 'value',
            name: 'm/s',
            nameTextStyle: {
              color: '#ffffff',
              align: 'right',
              fontSize: 10
            },
            min: 0,
            max: 0.8,
            interval: 0.1,
            axisLabel: {
              color: '#fff',
              fontSize: 10
            },
            splitLine: {
              show: true,
              lineStyle: {
                type: 'dashed',
                color: '#29506C',
                opacity: 0.5
              }
            }
          }
        ]
      },
      rightSecondChartSeries: [
        {
          name: '流速',
          data: [],
          rotateAry: [],
          type: 'line',
          smooth: true,
          symbol:
            'image://data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABEAAAASCAYAAAC9+TVUAAAACXBIWXMAAAsTAAALEwEAmpwYAAABKElEQVQ4jZ3TzyuEURTG8c87EZqkZCViQdkKe0sLVhZ2KJmUnyM/oqTQRGPvn7DQ/BUWlrKyoSykFJbKWLx3apreeZuZp27de87p23PPuTfKlcpSlEUfXtKK2tKSWMQ4cq1CMshjCBd4bQUyi9GwP8R6K5B81X4FBbw1A5nAdNW5AwfYbgaykxBbDW7eG4H0YyEh3oV97DUC2UJ7HYdruMJHGiQrtl1PWeziKA2yjN4UCPGoi/hMgmQkN7RW3eLxnyRB5jDSAAQ2cY2vWki+pvANG/jGJaaqcj3iN3MGUfjFE3gIBX+4wXEAQIR5nGMsxD4xjJ+Kk4qLR/F07mtclXGLOyzhFIPhWoUoVyoP4ClYLuK3XiOq1BkAK5iMcqXyDJ7DalY96P4HTkc3RcTA+RIAAAAASUVORK5CYII=',
          symbolSize: (value, params) => {
            return this.velocityAngle[params.dataIndex] > 0 ? 15 : 0
          },
          // showAllSymbol: true,
          symbolRotate: (value, params) => {
            return -this.velocityAngle[params.dataIndex]
          },
          lineStyle: {
            color: '#66B2FF',
            width: 2
          },
          areaStyle: {
            //颜色渐变函数 前四个参数分别表示四个位置依次为左、下、右、上
            color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
              { offset: 0, color: '#66B2FF' },
              { offset: 1, color: 'transparent' }
            ])
          }
        },
        {
          name: '流向',
          data: [],
          type: 'line',
          smooth: true,
          showSymbol: false,
          lineStyle: {
            // color: '#E8FF00',
            color:'rgb(0,0,0,0)',
            width: 2
          },
          // areaStyle: {
          //   //颜色渐变函数 前四个参数分别表示四个位置依次为左、下、右、上
          //   color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
          //     { offset: 0, color: '#E8FF00' },
          //     { offset: 1, color: 'transparent' }
          //   ])
          // }
        }
      ],
      tideVelocitySiteValue: 41,

      tideVelocityDate: [],
      velocityAngle: [],

      tideVelocityTableData: [],

      tideVelocityDateValue: '',
      isTideVelocityDateSign: false,

      fullChartName: '',
      fullScreenChartStyles: null,
      fullScreenChartSeries: null,

      dateStr: '',

      // 对话框开关
      dialogVisible: false,

      // 分页功能
      currentTableData: [],
      currentPage: 6,
      total: 144,
      pageSize: 24,
      // 最大页码按钮数量, 必须是大于等于 5 且小于等于 21 的奇数
      pageCount: 7,
    }
  },
  created() {
    this.getTideVelocityData()
    this.flowTimer = setInterval(()=>{
      this.getTideVelocityData()
      // console.log("潮流数据已重新获取")
    },60*2*1000)
  },
  mounted() {
    // window.addEventListener('mouseup', this.hideOption)
    window.addEventListener('mouseup', (e) => {
      if (e.target._prevClass !== 'select_date') {
        this.hideOption()
      }
    })
  },
  beforeDestroy() {
    // window.removeEventListener('mouseup', this.hideOption)
    clearInterval(this.flowTimer)
  },
  methods: {
    async getTideVelocityData() {
      try {
        const { data } = await request('fn/tidalCur', 24)
        this.tideVelocity = data.data.tidalCurrent
        this.chartsDataSet()
        this.updateSecondFullScreenData()
        this.setDateDateExtentStr()
        const lastData = this.tideVelocity.slice(-1)[0]
        Object.assign(mapApp.dataDevicePopData.seaTideData,{
          flowSpeed:lastData.VELOCITY,
          flowDirect:lastData.DIRECTION,
          timeStr:lastData.DATA_TIME
        })
      } catch (err) {
        console.log('获取数据失败！')
      }
    },
    // 设置数据时间范围
    setDateDateExtentStr() {
      this.dateStr = this.getDateStr(-1) + ' 至 ' + this.getDateStr(0)
    },
    // 根据日期偏移或者时间字符串
    getDateStr(dateOffset) {
      let currDate = new Date();
      if(dateOffset) {
        currDate.setDate(currDate.getDate() + dateOffset);
        return `${currDate.getFullYear()}-${currDate.getMonth() + 1}-${currDate.getDate()}`
      }else{
        return `${currDate.getFullYear()}-${currDate.getMonth() + 1}-${currDate.getDate()}`
      }
    },
    chartsDataSet() {
      if (this.tideVelocity) {
        let seriesData1 = []
        let seriesData2 = []
        // x轴坐标值
        let tempXData = []
        // 角度数组每次更新日期后 清空
        this.velocityAngle.splice(0, this.velocityAngle.length)
        let tempIndex = this.tideVelocity[0]['DATA_TIME'].indexOf('T')
        for (let i = 0; i < this.tideVelocity.length; i++) {
          if ( i % 6 === 5) {
            // seriesData1.push(Number(this.tideVelocity[i]['VELOCITY']).toFixed(2))
            seriesData1.push({
              date: this.tideVelocity[i]['DATA_TIME'].substring(0, 10),
              value: Number(this.tideVelocity[i]['VELOCITY']).toFixed(2)
            })
            seriesData2.push(this.tideVelocity[i]['DIRECTION'])
            // 箭头角度值
            this.velocityAngle.push(this.tideVelocity[i]['DIRECTION'])
            // x轴
            let dateStr2 = this.tideVelocity[i]['DATA_TIME'].substring(tempIndex + 1, tempIndex + 6)
            tempXData.push(dateStr2)
            
          }
        }
        this.rightSecondChartSeries[0].data = seriesData1
        this.rightSecondChartSeries[1].data = seriesData2
        this.rightSecondChartStyles.xAxis.data = tempXData
      }
    },

    // 更新全屏图表数据
    updateSecondFullScreenData(){
      this.tideVelocityTableData.length = 0;
      // 中转 styles
      this.fullScreenChartStyles = JSON.parse(JSON.stringify(this.rightSecondChartStyles))
      this.fullScreenChartStyles.grid = {
        left: '2%',
        right: '2%',
        top: '10%',
        bottom: '10%',
        containLabel: true
      }
      this.fullScreenChartStyles.dataZoom = [
        {
          type: 'inside',
          start: 0,
          end: 100
        },
        {
          height: 20,
          bottom: 6,
          start: 0,
          end: 100
        }
      ]
      this.fullScreenChartStyles.position = function (pt) {
        return [pt[0], '5%']
      }

      this.fullScreenChartStyles.xAxis.axisLabel.interval = 'auto'
      // 中转 series
      this.fullScreenChartSeries = JSON.parse(JSON.stringify(this.rightSecondChartSeries))
      // series[0]
      let tempDataAry1 = []
      let tempDataAry2 = []
      let tempXData = []
      for (let i = 0; i < this.tideVelocity.length; i++) {
        let tempIndex = this.tideVelocity[i]['DATA_TIME'].indexOf('T')
        let dateStr = this.tideVelocity[i]['DATA_TIME'].substring(tempIndex + 1, tempIndex + 6)
        let siteStr = this.tideVelocity[i]['STATION_ID']
        // if (siteStr === site) {  之前这里要判断id是否跟传参值一致，目前只有一种图表所以不需要
        if (siteStr) {
          tempXData.push(dateStr)
          tempDataAry1.push({
            date: this.tideVelocity[i]['DATA_TIME'].substring(0, 10),
            value: Number(this.tideVelocity[i]['VELOCITY']).toFixed(2)
          })
          tempDataAry2.push(this.tideVelocity[i]['DIRECTION'])
          this.tideVelocityTableData.push({
            index: i+1,
            date: this.tideVelocity[i]['DATA_TIME'].substring(0, 10) + ' ' + dateStr + ':00',
            velocity: Number(this.tideVelocity[i]['VELOCITY']).toFixed(2),
            angle: Number(this.tideVelocity[i]['DIRECTION'])
          })
        }
      }

      this.fullScreenChartStyles.xAxis.data = tempXData
      this.fullScreenChartStyles.tooltip.formatter = (params) => {
        let tempStr1 = `<span style="display:inline-block;margin-right:4px;border-radius:10px;width:10px;height:10px;background-color:#66B2FF"></span>`
        let dfStr = params[1].data ? '°' : ''
        let degreeStr = (params[1].data || 0) + dfStr
        let strRes =
          params[0].data.date + ' ' + params[0].axisValue + ':00' +
          '<div><div style="display:flex"><div style="margin-right:10px">' +
          tempStr1 +
          params[0].seriesName +
          '：<span style="font-weight:bold">' +
          params[0].data.value +
          '</span>m/s' +
          '</div><div>' +
          params[1].seriesName +
          '：<span style="font-weight:bold">' +
          degreeStr +
          '</span>' +
          '</div></div></div>'
        return strRes
      }
      // this.fullScreenChartStyles.dataZoom[0].start = 90;
      // console.log('fullScreenChartStyles:', this.fullScreenChartStyles)

      this.fullScreenChartSeries[0].data = tempDataAry1
      // this.$set(this.fullScreenChartSeries[0], 'showAllSymbol', true)
      this.fullScreenChartSeries[0].symbolSize = (value, params) => {
            return value > 0 ? 15 : 0
      }
      this.fullScreenChartSeries[0].symbolRotate = (value, params) => {
        return -tempDataAry2[params.dataIndex]
      }
      this.fullScreenChartSeries[1].data = tempDataAry2

      // 初始化表格当前数据
      this.handleCurrentChange(this.currentPage)
    },

    // 全屏图表开关
    secondFullScreenShow(site, date) {
      this.dialogVisible = true
      this.fullChartName = '潮流数据'
      // console.log("详细图表数据2:",this.fullScreenChartStyles)
    },

    // 翻页事件
    handleCurrentChange(index){
      // console.log("当前页码",index)
      let startIndex = this.pageSize*(index-1)
      let endIndex = this.pageSize*index
      this.currentTableData = this.tideVelocityTableData.slice(startIndex,endIndex)
    },

    clearFullScreenData() {
      // 这里需要注释掉，关闭全屏不再清除图表数据
      // this.fullScreenChartStyles = {}
      // this.fullScreenChartSeries = []
    },
    hideOption() {
      if (this.isTideVelocityDateSign) {
        this.isTideVelocityDateSign = false
      }
    },
    // 切换展示数据表格
    toggleDataTable() {
      this.isShowDataTable = !this.isShowDataTable 
    }
  }
}
</script>

<style lang="scss" scoped>
.container {
  position: relative;
}
.full_screen {
  .dialog_title {
    width: 100%;
    box-sizing: border-box;
    margin-bottom: 10px;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #687188;
    background-image: linear-gradient(#fff, #73abff);
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
    font-size: 18px;
    font-family: YouSheBiaoTiHei;
    .title_left {
      img {
        width: 16px;
        height: 16px;
        margin-right: 2px;
        margin-bottom: 3px;
      }
    }
  }
  .content {
    position: relative;
  }
  .show_data_btn{
    height: 20px;
    border-radius: 4px;
    background-color: #2196f3;
    margin: 10px 16px;
    text-align: center;
    font-size: 12px;
    color: #ffffff;
    padding-top: 2px;
    cursor: pointer;
  }
  .data_content{
    position: relative;
    padding: 0 16px 16px 16px;
    .header {
      display: flex;
      background-image: linear-gradient(
        rgba(144, 212, 255, 0.0),
        rgba(144, 212, 255, 0.4)
      );
      span {
        flex: 1;
        height: 20px;
        line-height: 20px;
        font-size: 12px;
        color: #53c8ff;
        text-align: center;
        
      }
    }
    .items_scroll{
      overflow-y: scroll;
      height: 500px;
      .items {
        height: 20px;
        line-height: 20px;
        color: #d5e7ff;
        text-align: center;
        border-bottom: 1px solid rgba(255, 255, 255, 0.18);
        display: flex;
        position: relative;
        font-size: 12px;
        > .item {
          flex: 1;
          user-select: auto;
        }
        &:hover{
          background: rgba(144, 212, 255, 0.2);
        }
      }
    }
  }
}
</style>
