<template>
  <div class="chart_container"></div>
</template>

<script>
import * as echarts from 'echarts'
export default {
  name: 'BasicChart',
  props: {
    stylesData: {
      type: Object
    },
    // 数据 类型
    seriesData: {
      type: Array,
      required: true
    }
  },
  computed: {
    option: function () {
      return {
        ...this.stylesData,
        series: this.seriesData
      }
    }
  },
  watch: {
    seriesData: {
      handler: function () {
        this.updataEchart()
      },
      deep: true
    }
  },
  // 组件状态值
  data() {
    return {
      myChart: null
    }
  },
  mounted() {
    this.initEchart()
  },
  updated: function () {
    this.$nextTick(function () {
      this.updataEchart()
    })
  },
  beforeDestroy() {
    this.myChart.clear() //清空实例
    this.myChart.dispose() //销毁实例
    this.myChart = null
  },
  // 组件方法
  methods: {
    initEchart() {
      this.myChart = echarts.init(this.$el)
      this.option && this.myChart.setOption(this.option)
    },
    updataEchart() {
      this.option && this.myChart.setOption(this.option)
      this.myChart.resize({
        width: 'auto',
        height: 'auto'
      })
    }
  }
}
</script>

<style scoped>
.chart_container {
  width: 100%;
  height: 100%;
}
</style>
