<template>
  <div class="container">
    <subtitle :iconImg="iconImg" name="风场数据" />
    <div class="btn_group">
      <div class="btn_screen" @click="thirdFullScreenShow(windFieldSiteValue)">详细数据</div>
      <!-- <div class="btn_site">观测站{{ windFieldSiteValue }}</div> -->
      <div class="btn_site">气象站</div>
      <div class="date_str">{{ dateStr }}</div>
    </div>
    <div style="width: 340px; height: 200px">
      <basic-chart :styles-data="rightThirdChartStyles" :series-data="rightThirdChartSeries" />
    </div>

    <el-dialog
      :show-close="false"
      :visible.sync="dialogVisible"
      :destroy-on-close="true"
      :append-to-body="true"
      width="70%"
      top="-50px"
      class="full_screen"
      @close="clearFullScreenData"
    >
      <div>
        <div class="dialog_title">
          <div class="title_left">
            <img src="../../../assets/images/overview/curve.png" alt />
            <span>{{ fullChartName }}</span>
            <span>-详细数据</span>
          </div>
          <div>
            <img src="../../../assets/images/overview/title.png" alt />
          </div>
        </div>
        <div class="content">
          <div v-for="(item, index) in fullScreenInfo" :key="index" style="width: 100%; height: 240px">
            <basic-chart v-if="dialogVisible" :styles-data="item.styles" :series-data="item.series" style="margin: 30px 0" />
          </div>
        </div>
        <div class="show_data_btn" @click="toggleDataTable">查看数据表格<span>{{isShowDataTable ? '▼' : '▲'}}</span></div>
        <div class="data_content" v-show="isShowDataTable">
          <div class="header">
            <span>序号</span>
            <span>日期时间</span>
            <span>瞬时风速(m/s)</span>
            <span>2min风速(m/s)</span>
            <span>2min风向(°)</span>
            <span>10min风速(m/s)</span>
            <span>10min风向(°)</span>
          </div>
          <div class="items_scroll">
            <div class="items-warp">
              <div class="items" v-for="(e,i) in currentTableData" :key="i">
                <span class="item">{{e.index}}</span>
                <span class="item">{{e.date}}</span>
                <span class="item">{{e.instSpeed}}</span>
                <span class="item">{{e.avg2mSpeed}}</span>
                <span class="item">{{e.avg2mDirect}}</span>
                <span class="item">{{e.avg10mSpeed}}</span>
                <span class="item">{{e.avg10mDirect}}</span>
              </div> 
            </div>
          </div>
        </div>
        <el-pagination
          class="pagination"
          layout="prev, pager, next"
          :total="total"
          :page-size="pageSize"
          :current-page="currentPage"
          :pager-count="pageCount"
          @current-change="handleCurrentChange"
        >
        </el-pagination>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import * as echarts from 'echarts'
import basicChart from '@/components/echarts/basic-chart.vue'
import subtitle from './subtitle.vue'
import request from '../../../utils/request'
import { default as mapApp } from '@/map/init.js'
export default {
  name: 'RightThird',
  components: { basicChart, subtitle },
  data() {
    return {
      isShowDataTable: true,
      // 标题图片
      iconImg: require('@/assets/images/overview/风场.png'),
      // 指示器点击
      index: 0,

      // 风场数据
      windField: null,
      windDirection: [],
      windDirection2: [],
      windDirection10: [],
      windFieldSite: [
        {
          value: 31,
          label: '气象站31'
        }
      ],
      rightThirdChartStyles: {
        legend: {
          data: ['瞬时风速', '两分钟平均风速', '十分钟平均风速'],
          right: '2%',
          top: '2%',
          textStyle: {
            color: '#fff',
            fontSize: 10
          }
        },
        grid: {
          left: '0',
          right: '5%',
          top: '15%',
          bottom: '0',
          containLabel: true
        },
        tooltip: {
          trigger: 'axis',
          confine: true,
          formatter: (params) => {
            let tempStr1 = `<span style="display:inline-block;margin-right:4px;border-radius:10px;width:10px;height:10px;background-color:#0099FF;"></span>`
            let tempStr2 = `<span style="display:inline-block;margin-right:4px;border-radius:10px;width:10px;height:10px;background-color:#FFFF33;"></span>`
            let tempStr3 = `<span style="display:inline-block;margin-right:4px;border-radius:10px;width:10px;height:10px;background-color:#99FF66;"></span>`
            let strRes =
              params[0].data.date + ' ' + params[0].axisValue + ':00' +
              '<div><div style="display:flex"><div style="margin-right:10px">' +
              tempStr1 +
              params[0].seriesName +
              '：<span style="font-weight:bold">' +
              params[0].data.value +
              '</span>m/s' +
              '</div><div>' +
              params[1].seriesName +
              '：<span style="font-weight:bold">' +
              params[1].data +
              '</span>°' +
              '</div></div></div>' +
              '<div><div style="display:flex"><div style="margin-right:10px">' +
              tempStr2 +
              params[2].seriesName +
              '：<span style="font-weight:bold">' +
              params[2].data +
              '</span>m/s' +
              '</div><div>' +
              params[3].seriesName +
              '：<span style="font-weight:bold">' +
              params[3].data +
              '</span>°' +
              '</div></div></div>' +
              '<div><div style="display:flex"><div style="margin-right:10px">' +
              tempStr3 +
              params[4].seriesName +
              '：<span style="font-weight:bold">' +
              params[4].data +
              '</span>m/s' +
              '</div><div>' +
              params[5].seriesName +
              '：<span style="font-weight:bold">' +
              params[5].data +
              '</span>°' +
              '</div></div></div>'
            return strRes
          }
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: [],
          axisLabel: {
            color: '#fff',
            fontSize: 10
          }
        },
        yAxis: {
          type: 'value',
          name: 'm/s',
          nameTextStyle: {
            color: '#ffffff',
            align: 'right',
            fontSize: 10
          },
          min: 0,
          max: 20,
          interval: 3,
          axisLabel: {
            color: '#fff',
            fontSize: 10
          },
          splitLine: {
            show: true,
            lineStyle: {
              type: 'dashed',
              color: '#29506C',
              opacity: 0.5
            }
          }
        }
      },
      rightThirdChartSeries: [
        {
          name: '瞬时风速',
          data: [],
          type: 'line',
          smooth: true,
          symbol:
            'image://data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAAAAXNSR0IArs4c6QAAAQxJREFUWEftlrFqwlAUhr8z+SISMltx6SB0Ls6SF7gibi6dCt07FQLe7OIDiHPBwc0HEF9B8AnCKYJ0kKaeJERBbsbL4f+/fLm5XOHOj9Tqn2kX4QknvmpOXYAVQo+ciLEcq0BUB/CaAPNzaYqTyW0BMt2jtH9LlT4jWZeFqGZgph8I7xdla5z0mwfINELZFRRNcJKWgShvwOsCGBaUnDZihJODFaIcQKavKMsr4R4no2YAvG6BjiH8BSffhjnsBrxOgU9LKLDBybNl1gbwpS1avP0RODivbYDL736wbEgbQNGreD0dRAk5MWMp+jP+FREAgoFgIBgIBoKBYCAYeAgDMTnJfW5EllvnlZkf8XiSIfmkGqMAAAAASUVORK5CYII=',
          symbolSize: 20,
          symbolRotate: (value, params) => {
            return -this.windDirection[params.dataIndex] + 180
          },
          lineStyle: {
            color: '#0099FF',
            width: 2
          },
          areaStyle: {
            //颜色渐变函数 前四个参数分别表示四个位置依次为左、下、右、上
            color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
              { offset: 0, color: '#0099FF' },
              { offset: 1, color: 'transparent' }
            ])
          }
        },
        {
          name: '风向',
          data: [],
          type: 'line',
          smooth: true,
          showSymbol: false,
          lineStyle: {
            color: '#0099FF',
            width: 0
          }
        },
        {
          name: '两分钟平均风速',
          data: [],
          type: 'line',
          smooth: true,
          symbol:
            'image://data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAAAAXNSR0IArs4c6QAAARhJREFUWEftlr1KA0EYRc/Fws6nCCGtO5LGImAt1rJPYJHOxkqwt02RBxAfQKyFFKl218JKfIWAvaBXQgrxZ+PshhiQ2XL4uPfs2dlhxIYfrdJv7+7BVpCKcducFQHCLdCHl6708NwGojWAHXLgalGqkVQM/xrgCeh8lL4NpPtJU4hWBuxwAZx/LtNEKgZrB7D7XXh9/LnIQ6kaNYFobMDOrkHHNSXzjdiVylksRCMAOzsE3SwP91iqTtYEEEogiwg/kMq7iDmiDdjhFLiMCQVPpWo/ZjYKwO5sw87Z90AdLdY8Bb5+91nMhowCqHsTO8wPohzUk4qaP2O5hwSQDCQDyUAykAwkA8nAvzDQA+UbuRHFXDp/m3kHjNqfIX08dgwAAAAASUVORK5CYII=',
          symbolSize: 20,
          symbolRotate: (value, params) => {
            return -this.windDirection2[params.dataIndex] + 180
          },
          lineStyle: {
            color: '#FFFF33',
            width: 2
          },
          areaStyle: {
            //颜色渐变函数 前四个参数分别表示四个位置依次为左、下、右、上
            color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
              { offset: 0, color: '#FFFF33' },
              { offset: 1, color: 'transparent' }
            ])
          }
        },
        {
          name: '风向',
          data: [],
          type: 'line',
          smooth: true,
          showSymbol: false,
          lineStyle: {
            color: '#FFFF33',
            width: 0
          }
        },
        {
          name: '十分钟平均风速',
          data: [],
          type: 'line',
          smooth: true,
          symbol:
            'image://data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAAAAXNSR0IArs4c6QAAASNJREFUWEftlrFKA0EURe9li/yILKljsLEIpBZr2X5nlhArGyshvZUQknl98APEOpAinR8g/kLA2mJ9EtQmsubthhiQ2XJ43Hv27OwwxIEf7tI/Vd8l9NhTQtOcHQHyR4InJZJ0wMlrE4jGAEFdBmD2VTr2lOGfAoi6FwWOvksV7BUMi7oQjQxMNR8RvNkoW3hKb+8Aonmq4HNF0dBTxnUgahsI6u4BXFSUrDdi6ikrK0QtANHiTPH+sCU8eEqxF4Cg7glAxxDe95S5YQ5mA0HdFYBbSyiApaecWmZNAHd62Wrh7fpnIM8/13QJYPO7rywb0gRQ9SZB3fogykok7QEnVX/GryIiQDQQDUQD0UA0EA1EA//CQLtEkh3kRmS5dG6b+QB+RrQhq0R40wAAAABJRU5ErkJggg==',
          symbolSize: 20,
          symbolRotate: (value, params) => {
            return -this.windDirection10[params.dataIndex] + 180
          },
          lineStyle: {
            color: '#99FF66',
            width: 2
          },
          areaStyle: {
            //颜色渐变函数 前四个参数分别表示四个位置依次为左、下、右、上
            color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
              { offset: 0, color: '#99FF66' },
              { offset: 1, color: 'transparent' }
            ])
          }
        },
        {
          name: '风向',
          data: [],
          type: 'line',
          smooth: true,
          showSymbol: false,
          lineStyle: {
            color: '#99FF66',
            width: 0
          }
        }
      ],
      windFieldSiteValue: 31,
      windFieldDate: [],
      windFieldDateValue: '',
      isWindFieldDateSign: false,

      fullScreenInfo: [],

      fullChartName: '',
      fullScreenChartStyles: null,
      fullScreenChartSeries: null,

      dateStr: '',

      // 风场数据图表
      windFieldTableData: [],

      // 对话框开关
      dialogVisible: false,

      // 分页功能
      currentTableData: [],
      currentPage: 6,
      total: 144,
      pageSize: 24,
      // 最大页码按钮数量, 必须是大于等于 5 且小于等于 21 的奇数
      pageCount: 7,
    }
  },
  created() {
    this.getWindFieldData()
    this.windTimer = setInterval(()=>{
      this.getWindFieldData()
      // console.log("风数据已重新获取")
    },60*2*1000)
  },
  mounted() {
    window.addEventListener('mouseup', (e) => {
      if (e.target._prevClass !== 'select_date') {
        this.hideOption()
      }
    })
  },
  beforeDestroy() {
    // window.removeEventListener('mouseup', this.hideOption)
    clearInterval(this.windTimer)
  },
  methods: {
    // 风场相关
    async getWindFieldData() {
      try {
        const { data } = await request('fn/wind', 24)
        this.windField = data.data.wind
        this.windChartDataSet()
        this.updateThirdFullScreenData()
        this.setDateDateExtentStr();
      } catch (err) {
        console.log('获取数据失败！')
      }
    },
    // 设置数据时间范围
    setDateDateExtentStr() {
      this.dateStr = this.getDateStr(-1) + ' 至 ' + this.getDateStr(0)
    },
    // 根据日期偏移或者时间字符串
    getDateStr(dateOffset) {
      let currDate = new Date();
      if(dateOffset) {
        currDate.setDate(currDate.getDate() + dateOffset);
        return `${currDate.getFullYear()}-${currDate.getMonth() + 1}-${currDate.getDate()}`
      }else{
        return `${currDate.getFullYear()}-${currDate.getMonth() + 1}-${currDate.getDate()}`
      }
    },
    windChartDataSet() {
      console.log('wind')
      if (this.windField) {
        let seriesData1 = []
        let seriesData2 = []
        let seriesData3 = []
        let seriesData4 = []
        let seriesData5 = []
        let seriesData6 = []
        // x轴坐标值
        let tempXData = []
        this.windDirection.splice(0, this.windDirection.length)
        this.windDirection2.splice(0, this.windDirection2.length)
        this.windDirection10.splice(0, this.windDirection10.length)
        let tempIndex = this.windField[0]['DATA_TIME'].indexOf('T')
        for (let i = 0; i < this.windField.length; i++) {
          if ( i % 6 === 5) {
            // seriesData1.push(Number(this.windField[i]['INST_SPEED']).toFixed(2))
            seriesData1.push({
              date: this.windField[i]['DATA_TIME'].substring(0, 10),
              value: Number(this.windField[i]['INST_SPEED']).toFixed(2)
            })
            seriesData2.push(this.windField[i]['INST_DIRECTION'])
            this.windDirection.push(this.windField[i]['INST_DIRECTION'])
            seriesData3.push(Number(this.windField[i]['AVG_SPEED_2M']).toFixed(2))
            seriesData4.push(this.windField[i]['AVG_DIRECTION_2M'])
            this.windDirection2.push(this.windField[i]['AVG_DIRECTION_2M'])
            seriesData5.push(Number(this.windField[i]['AVG_SPEED_10M']).toFixed(2))
            seriesData6.push(this.windField[i]['AVG_DIRECTION_10M'])
            this.windDirection10.push(this.windField[i]['AVG_DIRECTION_10M'])
            // x轴
            let dateStr2 = this.windField[i]['DATA_TIME'].substring(tempIndex + 1, tempIndex + 6)
            tempXData.push(dateStr2)
          }
        }
        this.rightThirdChartSeries[0].data = seriesData1
        this.rightThirdChartSeries[1].data = seriesData2
        this.rightThirdChartSeries[2].data = seriesData3
        this.rightThirdChartSeries[3].data = seriesData4
        this.rightThirdChartSeries[4].data = seriesData5
        this.rightThirdChartSeries[5].data = seriesData6
        this.rightThirdChartStyles.xAxis.data = tempXData

        // 设置观测设备弹窗数据
        this.setDataDevicePopData(this.getCurrNearTimeData());
      }
    },

    // 获取离当前时间点最接近的数据
    getCurrNearTimeData() {
      let currDate = new Date();
      let timeIndex = 0;
      let tempMin = null;
      for (let i=0, len=this.windField.length; i < len; i++) {
        let timeStr = this.windField[i]['DATA_TIME'];
        let dateStr = `${timeStr.substring(0, 10)} ${timeStr.substring(11, 16)}`;
        let targetDate = new Date(dateStr);
        let minute = parseInt(Math.abs(currDate - targetDate) / 1000 / 60);
        if(!tempMin) tempMin = minute;
        if(minute < tempMin){
          timeIndex = i;
          tempMin = minute;
        }
      }
      return this.windField[timeIndex]
    },

    // 设置数据设施弹窗数据
    setDataDevicePopData(data) {
      mapApp.dataDevicePopData.weatherData.instantWindSD = [Number(data['INST_SPEED']).toFixed(2), data['INST_DIRECTION']];
      mapApp.dataDevicePopData.weatherData.twoMinWindSD = [Number(data['AVG_SPEED_2M']).toFixed(2), data['AVG_DIRECTION_2M']];
      mapApp.dataDevicePopData.weatherData.tenMinWindSD = [Number(data['AVG_SPEED_10M']).toFixed(2), data['AVG_DIRECTION_10M']];
      mapApp.dataDevicePopData.weatherData.timeStr = `${data['DATA_TIME'].substring(0, 10)}  ${data['DATA_TIME'].substring(11, 19)}`;
      // 缓存数据
      mapApp.storeDataDevicePopData();
    },

    // 更新全屏图表数据
    updateThirdFullScreenData(){
      this.windFieldTableData.length = 0;

      // series[0]
      let tempDataAry1 = []
      let tempDataAry2 = []
      let tempDataAry3 = []
      let tempDataAry4 = []
      let tempDataAry5 = []
      let tempDataAry6 = []

      let tempXData = []

      let fullScreenData = [
        { styles: {}, series: [] },
        { styles: {}, series: [] },
        { styles: {}, series: [] }
      ]
      // 全屏图表的 styles 数据
      for (let i = 0; i < fullScreenData.length; i++) {
        fullScreenData[i].styles = JSON.parse(JSON.stringify(this.rightThirdChartStyles))
        fullScreenData[i].styles.grid = {
          left: '2%',
          right: '2%',
          top: '10%',
          bottom: '12%',
          containLabel: true
        }
        fullScreenData[i].styles.dataZoom = [
          {
            type: 'inside',
            start: 0,
            end: 100
          },
          {
            height: 20,
            bottom: 6,
            start: 0,
            end: 100
          }
        ]
        fullScreenData[i].styles.position = function (pt) {
          return [pt[0], '5%']
        }
      }
      // 全屏图表的 series 数据
      let tempSeries = JSON.parse(JSON.stringify(this.rightThirdChartSeries))
      fullScreenData[0].series[0] = tempSeries[0]
      fullScreenData[0].series[1] = tempSeries[1]
      fullScreenData[1].series[0] = tempSeries[2]
      fullScreenData[1].series[1] = tempSeries[3]
      fullScreenData[2].series[0] = tempSeries[4]
      fullScreenData[2].series[1] = tempSeries[5]

      // data 数据 和 symbol 相关数据
      for (let i = 0; i < this.windField.length; i++) {
        let tempIndex = this.windField[i]['DATA_TIME'].indexOf('T')
        let dateStr2 = this.windField[i]['DATA_TIME'].substring(tempIndex + 1, tempIndex + 6)

        tempXData.push(dateStr2)

        tempDataAry1.push({
          date: this.windField[i]['DATA_TIME'].substring(0, 10),
          value: Number(this.windField[i]['INST_SPEED']).toFixed(2)
        })
        tempDataAry2.push(this.windField[i]['INST_DIRECTION'])
        tempDataAry3.push({
          date: this.windField[i]['DATA_TIME'].substring(0, 10),
          value: Number(this.windField[i]['AVG_SPEED_2M']).toFixed(2)
        })
        tempDataAry4.push(this.windField[i]['AVG_DIRECTION_2M'])
        tempDataAry5.push({
          date: this.windField[i]['DATA_TIME'].substring(0, 10),
          value: Number(this.windField[i]['AVG_SPEED_10M']).toFixed(2)
        })
        tempDataAry6.push(this.windField[i]['AVG_DIRECTION_10M'])

        this.windFieldTableData.push({
          index: i+1,
          date: this.windField[i]['DATA_TIME'].substring(0, 10) + ' ' + dateStr2 + ':00',
          instSpeed: this.windField[i]['INST_DIRECTION'],
          avg2mSpeed: Number(this.windField[i]['AVG_SPEED_2M']).toFixed(2),
          avg2mDirect: this.windField[i]['AVG_DIRECTION_2M'],
          avg10mSpeed: Number(this.windField[i]['AVG_SPEED_10M']).toFixed(2),
          avg10mDirect: this.windField[i]['AVG_DIRECTION_10M'],
        })
      }
      // 全屏图表的 styles 其他数据
      let symbolColorAry = ['#0099FF', '#FFFF33', '#99FF66']
      let seriesNameAry = ['瞬时风速', '两分钟平均风速', '十分钟平均风速']
      for (let i = 0; i < fullScreenData.length; i++) {
        fullScreenData[i].styles.color = [symbolColorAry[i]]
        fullScreenData[i].styles.legend = {
          right: '2%',
          top: '2%',
          formatter: '{name}/风向',
          data: [
            {
              name: seriesNameAry[i]
            }
          ],
          // 设置文本为红色}],
          textStyle: {
            color: '#fff'
          }
        }
        fullScreenData[i].styles.xAxis.data = tempXData
        fullScreenData[i].styles.tooltip.formatter = (params) => {
          let tempStr1 = `<span style="display:inline-block;margin-right:4px;border-radius:10px;width:10px;height:10px;background-color:${symbolColorAry[i]}"></span>`
          let strRes =
            params[0].data.date + ' ' + params[0].axisValue + ':00' +
            '<div><div style="display:flex"><div style="margin-right:10px">' +
            tempStr1 +
            params[0].seriesName +
            '：<span style="font-weight:bold">' +
            params[0].data.value +
            '</span>m/s' +
            '</div><div>' +
            params[1].seriesName +
            '：<span style="font-weight:bold">' +
            params[1].data +
            '</span>°' +
            '</div></div></div>'
          return strRes
        }
      }
      // 第一张全屏图表
      fullScreenData[0].series[0].data = tempDataAry1
      // fullScreenData[0].series[0].showAllSymbol = true
      fullScreenData[0].series[0].symbolRotate = (value, params) => {
        return -tempDataAry2[params.dataIndex] + 180
      }
      fullScreenData[0].series[1].data = tempDataAry2
      // 第二张
      fullScreenData[1].series[0].data = tempDataAry3
      fullScreenData[1].series[0].symbolRotate = (value, params) => {
        return -tempDataAry4[params.dataIndex] + 180
      }
      fullScreenData[1].series[1].data = tempDataAry4
      // 第三张
      fullScreenData[2].series[0].data = tempDataAry5
      fullScreenData[2].series[0].symbolRotate = (value, params) => {
        return -tempDataAry6[params.dataIndex] + 180
      }
      fullScreenData[2].series[1].data = tempDataAry6
      this.fullScreenInfo = fullScreenData

      // 初始化表格当前数据
      this.handleCurrentChange(this.currentPage)
    },

    // 全屏图表开关
    thirdFullScreenShow() {
      this.dialogVisible = true
      this.fullChartName = '风场数据'
      // console.log("详细图表数据3:",this.fullScreenInfo)
    },

    // 翻页事件
    handleCurrentChange(index){
      // console.log("当前页码",index)
      let startIndex = this.pageSize*(index-1)
      let endIndex = this.pageSize*index
      this.currentTableData = this.windFieldTableData.slice(startIndex,endIndex)
    },

    // 大屏公用
    clearFullScreenData() {
      // 注释掉，关闭开关不再清除数据
      // this.fullScreenChartStyles = {}
      // this.fullScreenChartSeries = []
    },
    hideOption() {
      this.isWindFieldDateSign = false
      this.isVisibilityDateSign = false
    },
    toggle(step) {
      let newIndex = this.index + step
      if (newIndex > 1) newIndex = 0
      if (newIndex < 0) newIndex = 1
      this.index = newIndex
    },
    // 切换展示数据表格
    toggleDataTable() {
      this.isShowDataTable = !this.isShowDataTable 
    }
  }
}
</script>

<style lang="scss" scoped>
.container {
  position: relative;
  &:hover {
    .carousel-btn {
      opacity: 1;
    }
  }
}

.el-dialog {
  margin-top: 0vh;
}
.full_screen {
  .dialog_title {
    width: 100%;
    box-sizing: border-box;
    margin-bottom: 10px;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #687188;
    background-image: linear-gradient(#fff, #73abff);
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
    font-size: 18px;
    font-family: YouSheBiaoTiHei;
    .title_left {
      img {
        width: 16px;
        height: 16px;
        margin-right: 2px;
        margin-bottom: 3px;
      }
    }
  }
  .show_data_btn{
    height: 20px;
    border-radius: 4px;
    background-color: #2196f3;
    margin: 10px 16px;
    text-align: center;
    font-size: 12px;
    color: #ffffff;
    padding-top: 2px;
    cursor: pointer;
  }
  .data_content{
    position: relative;
    padding: 0 16px 16px 16px;
    .header {
      display: flex;
      background-image: linear-gradient(
        rgba(144, 212, 255, 0.0),
        rgba(144, 212, 255, 0.4)
      );
      span {
        flex: 1;
        height: 20px;
        line-height: 20px;
        font-size: 12px;
        color: #53c8ff;
        text-align: center;
        
      }
    }
    .items_scroll{
      overflow-y: scroll;
      height: 500px;
      .items {
        height: 20px;
        line-height: 20px;
        color: #d5e7ff;
        text-align: center;
        border-bottom: 1px solid rgba(255, 255, 255, 0.18);
        display: flex;
        position: relative;
        font-size: 12px;
        > .item {
          flex: 1;
          user-select: auto;
        }
        &:hover{
          background: rgba(144, 212, 255, 0.2);
        }
      }
    }
  }
}
.btn_group {
  width: 330px;
}


</style>
