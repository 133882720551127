<template>
  <div class="container">
    <subtitle :iconImg="iconImg" name="船舶船只" />
    <div class="second_content">
      <div style="width: 330px; height: 200px">
        <basic-chart :styles-data="leftSecondChartStyles" :series-data="leftSecondChartSeries" />
      </div>

      <ul>
        <li>
          <span>渔船</span>
          <span>{{ boatsTypeData['渔船'] }}</span>
        </li>
        <li>
          <span>商业散货船</span>
          <span>{{ boatsTypeData['商业散货船'] }}</span>
        </li>
        <li>
          <span>拖轮</span>
          <span>{{ boatsTypeData['拖轮'] }}</span>
        </li>
        <li>
          <span>巡逻船</span>
          <span>{{ boatsTypeData['巡逻船'] }}</span>
        </li>
        <li>
          <span>自有散货船</span>
          <span>{{ boatsTypeData['自有散货船'] }}</span>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import * as echarts from 'echarts'
import basicChart from '@/components/echarts/basic-chart.vue'
import subtitle from './subtitle.vue'
import request from '../../../utils/request-common'

export default {
  name: 'LeftSecond',
  components: { basicChart, subtitle },
  data() {
    return {
      boatsTypeData: {
        "渔船": 55,
        "商业散货船": 23,
        "拖轮": 12,
        "巡逻船": 5,
        "自有散货船": 4,
      },
      // 标题图片
      iconImg: require('@/assets/images/overview/2.png'),
      leftSecondChartStyles: {
        tooltip: {
          trigger: 'item',
          formatter: '{a} <br/>{b} : {c} ({d}%)'
        }
      },
      leftSecondChartSeries: [
        {
          name: '船只数量',
          type: 'pie',
          radius: ['20%', '70%'],
          center: ['25%', '50%'],
          data: [
            {
              value: 55,
              name: '渔船',
              itemStyle: {
                color: new echarts.graphic.LinearGradient(0, 0, 1, 0, [
                  { offset: 0, color: '#0088C8' },
                  { offset: 1, color: '#00CCFE' }
                ])
              }
            },
            {
              value: 23,
              name: '商业散货船',
              itemStyle: {
                color: new echarts.graphic.LinearGradient(1, 0, 0, 0, [
                  { offset: 0, color: '#0055FF' },
                  { offset: 1, color: '#5D93FC' }
                ])
              }
            },
            {
              value: 12,
              name: '拖轮',
              itemStyle: {
                color: new echarts.graphic.LinearGradient(1, 0, 0, 0, [
                  { offset: 0, color: '#746C12' },
                  { offset: 1, color: '#EDE886' }
                ])
              }
            },
            {
              value: 5,
              name: '巡逻船',
              itemStyle: {
                color: new echarts.graphic.LinearGradient(0, 0, 1, 0, [
                  { offset: 0, color: '#AAAAAA' },
                  { offset: 1, color: '#DEDEDE' }
                ])
              }
            },
            {
              value: 4,
              name: '自有散货船',
              itemStyle: {
                color: new echarts.graphic.LinearGradient(0, 0, 1, 0, [
                  { offset: 0, color: '#248624' },
                  { offset: 1, color: '#46ED46' }
                ])
              }
            }
          ],
          roseType: 'radius',
          label: {
            show: false
          },
          animationType: 'scale',
          animationEasing: 'elasticOut',
          animationDelay: function (idx) {
            return Math.random() * 200
          }
        }
      ],
      // secImgSrcAry: [
      //   { imgUrl: require('@/assets/images/overview/巡逻船.png'), name: '巡逻船' },
      //   { imgUrl: require('@/assets/images/overview/拖轮船.png'), name: '拖轮船' },
      //   { imgUrl: require('@/assets/images/overview/集装箱船.png'), name: '集装箱船' },
      //   { imgUrl: require('@/assets/images/overview/商业散货船.png'), name: '商业散货船' },
      //   { imgUrl: require('@/assets/images/overview/集装箱船.png'), name: '集装箱船' }
      // ],
      secImgSelected: 0
    }
  },
  created() {
    this.getBoatData()
  },
  methods: {
    // 获取潮位数据
    async getBoatData() {
      try {
        if (!this.isLoadedData) {
          const { data } = await request('fn/boatStat', {})
          let result = data.data;
          console.log("boat data:", result)
          this.boatsTypeData['渔船'] = result.boatData.fishing_boat;
          this.boatsTypeData['商业散货船'] = result.boatData.commercial_bulk;
          this.boatsTypeData['自有散货船'] = result.boatData.own_bulk;
          this.boatsTypeData['拖轮'] = result.boatData.carrier;
          this.boatsTypeData['巡逻船'] = result.boatData.patrol_boat;

          this.changeChartData()
        }
      } catch (err) {
        console.log('获取数据失败！', err)
      }
    },
    // 更新图表数据
    changeChartData() {
      for(let data of this.leftSecondChartSeries[0].data){
        for(let key in this.boatsTypeData){
          if(key === data.name){
            data.value = this.boatsTypeData[key]
            break;
          }
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.container {
  position: relative;
  font-family: SiYuan;
}
.second_content {
  position: relative;
  padding-top: 25px;
  display: flex;
  ul {
    position: absolute;
    top: 20px;
    right: 10px;
    width: 120px;
    li {
      position: relative;
      height: 30px;
      padding-left: 16px;
      margin-bottom: 5px;
      line-height: 30px;
      font-size: 14px;
      display: flex;
      justify-content: space-between;
      border-bottom: 1px solid #8d918d;
      span {
        color: #a7eaf5;
        &:nth-of-type(2) {
          color: #fff;
          font-weight: bold;
        }
      }
      &::before {
        content: '';
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        width: 4px;
        height: 4px;
        border-radius: 50%;
        border: 4px solid #00bff4;
      }
      &:nth-child(2)::before {
        border: 4px solid #4483fd;
      }
      &:nth-child(3)::before {
        border: 4px solid #d0ca6a;
      }
      &:nth-child(4)::before {
        border: 4px solid #c9c9c9;
      }
      &:nth-child(5)::before {
        border: 4px solid #41de41;
      }
    }
  }
}
</style>
