<template>
  <div class="overview_contianer">
    <!-- 左侧栏内容区 -->
    <aside class="aside_left">
      <div class="left_first">
        <leftFirstContent />
      </div>
      <!-- <div class="left_second">
        <leftSecondContent />
      </div> -->
      <div class="left_third">
        <leftThirdContent />
      </div>
    </aside>
    <!-- 右侧栏内容区 -->
    <aside class="aside_right">
      <div class="right_first">
        <rightFirstContent />
      </div>
      <div class="right_second">
        <rightSecondContent />
      </div>
      <div class="right_third">
        <rightCarousel>
          <rightThirdContent />
          <template v-slot:second>
            <rightFourthContent />
          </template>
        </rightCarousel>
      </div>
    </aside>

    <pop-home></pop-home>
    <pop-ship></pop-ship>
    <pop-forecast></pop-forecast>
  </div>
</template>

<script>
import { default as mapApp } from '@/map/init.js'
import popHome from '../map/popMarker/pop-home.vue'
import popShip from '../map/popMarker/pop-ship.vue'
import popForecast from '../map/popMarker/pop-forecast.vue'

import leftFirstContent from './components/left-first-content.vue'
import leftSecondContent from './components/left-second-content.vue'
import leftThirdContent from './components/left-third-content.vue'
import rightFirstContent from './components/right-first-content.vue'
import rightSecondContent from './components/right-second-content.vue'
import rightCarousel from './components/right-carousel.vue'
import rightThirdContent from './components/right-third-content.vue'
import rightFourthContent from './components/right-fourth-content.vue'

import baseContainer from '../../components/container/base-container.vue'
export default {
  name: 'Overview',
  components: {
    popHome,
    popShip,
    popForecast,

    leftFirstContent,
    // leftSecondContent,
    leftThirdContent,
    rightFirstContent,
    rightSecondContent,
    rightCarousel,
    rightThirdContent,
    rightFourthContent,

    // baseContainer
  },
  // mounted() {
  //   console.log(mapApp, this);
  //   mapApp.overviewVueComponent = this
  // },
  created () {
    if (mapApp.overviewMapActions) {
      mapApp.overviewMapActions.resetView()
    } else {
      mapApp.addEventListener('mapAppInitComplete', () => {
        mapApp.overviewMapActions.resetView()
      })
      mapApp.addEventListener('mapLoadComplete', () => {
        this.showHomePopMarker()
      })
    }
  },
  mounted () {
    // 处理屏幕宽高比
    // this.handleScreenAuto()
    // window.addEventListener('onresize',this.handleScreenAuto)
  },
  beforeDestroy () {
    // window.removeEventListener('onresize',this.handleScreenAuto)
    mapApp.boatsMapActions.closeShipPop()
  },
  methods: {
    // home 气泡展示
    showHomePopMarker () {
      mapApp.overviewMapActions.showHomePopMarker()
    },
    // 多分辨率显示器适配
    handleScreenAuto () {
      const designDraftWidth = 1920;//设计稿的宽度
      const designDraftHeight = 1080;//设计稿的高度
      const currentWidth = document.documentElement.clientWidth // 当前浏览器宽度
      const currentHeight = document.documentElement.clientHeight // 当前浏览器高度
      // console.log(currentWidth,currentHeight)
      //宽高相同缩放比例，选择较小的
      const scale = currentWidth / currentHeight < designDraftWidth / designDraftHeight ?
          (currentWidth / designDraftWidth) :
          (currentHeight / designDraftHeight);
      (document.querySelector('.overview_contianer')).style.transform = `scale(${scale}) translate(-50%)`;
      
      // 宽高不同缩放比例
      // const widthScale =  currentWidth / designDraftWidth;
      // const heightScale = currentHeight / designDraftHeight;
      // // console.log(widthScale,heightScale)
      // (document.querySelector('.overview_contianer')).style.transform = `scale(${widthScale},${heightScale})`;
    }
  }
}
</script>

<style lang="scss">

.overview_contianer {
  .aside_left {
    position: absolute;
    left: 0px;
    width: 400px;
    height: 100%;
    box-sizing: border-box;
    padding-left: 25px;
    font-family: WenQuanDengKuanWeiMiHei;
    background-image: linear-gradient(to right, rgb(3, 12, 30), rgb(3, 12, 30, 0.6) 70%, rgba(3, 12, 30, 0));
    pointer-events: none;
    z-index: 99;

    >div {
      position: relative;
      top: 120px;
      width: 330px;
    }

    .left_first {
      margin-bottom: 10px;
      animation: enter_left 1s;
    }

    .left_second {
      margin-bottom: 10px;
      opacity: 0;
      animation: enter_left 1s 0.3s forwards;
      pointer-events: auto;
    }

    .left_third {
      opacity: 0;
      animation: enter_left 1s 0.6s forwards;
    }
  }

  .aside_right {
    position: absolute;
    right: 0;
    width: 400px;
    height: 100%;
    box-sizing: border-box;
    padding-left: 45px;
    font-family: SiYuan;
    background-image: linear-gradient(to left, rgb(3, 12, 30), rgb(3, 10, 24, 0.6) 70%, rgba(0, 0, 0, 0));
    pointer-events: none;

    >div {
      position: relative;
      top: 120px;
      width: 330px;
      pointer-events: auto;
    }

    .right_first {
      animation: enter_right 1s;
      margin-bottom: 15px;
    }

    .right_second {
      opacity: 0;
      animation: enter_right 1s 0.3s forwards;
      margin-bottom: 15px;
    }

    .right_third {
      opacity: 0;
      animation: enter_right 1s 0.6s forwards;
    }
  }
}

/* 媒体查询用于响应式布局 */
@media (max-width: 1200px) {
  .overview_contianer {
    .aside_left {
      position: absolute;
      left: 0px;
      width: 400px;
      height: 100%;
      box-sizing: border-box;
      padding-left: 25px;
      font-family: WenQuanDengKuanWeiMiHei;
      background-image: linear-gradient(to right, rgb(3, 12, 30), rgb(3, 12, 30, 0.6) 70%, rgba(3, 12, 30, 0));
      pointer-events: none;
      z-index: 99;

      >div {
        position: relative;
        top: 120px;
        width: 330px;
      }

      .left_first {
        margin-bottom: 10px;
        animation: enter_left 1s;
      }

      .left_second {
        margin-bottom: 10px;
        opacity: 0;
        animation: enter_left 1s 0.3s forwards;
        pointer-events: auto;
      }

      .left_third {
        opacity: 0;
        animation: enter_left 1s 0.6s forwards;
      }
    }

    .aside_right {
      position: absolute;
      right: 0;
      width: 400px;
      height: 100%;
      box-sizing: border-box;
      padding-left: 45px;
      font-family: SiYuan;
      background-image: linear-gradient(to left, rgb(3, 12, 30), rgb(3, 10, 24, 0.6) 70%, rgba(0, 0, 0, 0));
      pointer-events: none;

      >div {
        position: relative;
        top: 120px;
        width: 330px;
        pointer-events: auto;
      }

      .right_first {
        animation: enter_right 1s;
        margin-bottom: 15px;
      }

      .right_second {
        opacity: 0;
        animation: enter_right 1s 0.3s forwards;
        margin-bottom: 15px;
      }

      .right_third {
        opacity: 0;
        animation: enter_right 1s 0.6s forwards;
      }
    }
  }
}

.btn_group {
  position: relative;
  height: 24px;
  width: 100%;
  top: 5px;
  display: flex;
  justify-content: space-between;
  pointer-events: auto;

  >div {
    position: relative;
    border: 1px solid rgba(255, 255, 255, 0.3);
    box-sizing: border-box;
    border-radius: 2px;
    line-height: 22px;
    padding: 0 5px;
    // margin-left: 30px;
    font-size: 12px;
    color: rgba(255, 255, 255, 0.9);
    cursor: pointer;
  }

  .date_str {
    position: absolute;
    right: 100px;
    font-size: 12px;
    color: rgba(255, 255, 255, 0.9);
    border: none;
  }
}

.el-dialog {
  top: 8%;
  background-color: #040e1e7c;

  &__header {
    display: none;
  }

  &__body {
    padding: 10px;
  }
}

@keyframes circle_rotate {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}
</style>
