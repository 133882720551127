<template>
  <div class="container">
    <ul class="carousel-body">
      <li class="carousel-item" :style="{ transform: `translateX(${-index * 340}px)` }">
        <slot></slot>
      </li>
      <li class="carousel-item" :style="{ transform: `translateX(${-index * 340}px)` }">
        <slot name="second"></slot>
      </li>
    </ul>
    <div class="carousel-btnGroup">
      <!-- 上一张 -->
      <a href="javascript:;" class="carousel-btn" @click="toggle(-1)">
        <i class="el-icon-arrow-left"></i>
      </a>
      <!-- 下一张 -->
      <a href="javascript:;" class="carousel-btn" @click="toggle(1)">
        <i class="el-icon-arrow-right"></i>
      </a>
    </div>

    <!-- 指示器 -->
    <div class="carousel-indicator">
      <span :class="{ active: index === 0 }" @click="toggle(-1)"></span>
      <span :class="{ active: index === 1 }" @click="toggle(1)"></span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'RightCarousel',
  data() {
    return {
      // 指示器点击
      index: 0
    }
  },

  methods: {
    toggle(step) {
      let newIndex = this.index + step
      if (newIndex > 1) newIndex = 0
      if (newIndex < 0) newIndex = 1
      this.index = newIndex
    }
  }
}
</script>

<style lang="scss" scoped>
.container {
  position: relative;
  &:hover {
    .carousel-btn {
      opacity: 1;
    }
  }
}
.carousel-body {
  display: flex;
  width: 340px;
  overflow-x: hidden;
  .carousel-item {
    transition: all 0.8s;
  }
}
.carousel-btnGroup {
  position: absolute;
  top: 50%;
  left: 18px;
  width: 300px;
  display: flex;
  justify-content: space-between;
  pointer-events: none;
  .carousel-btn {
    width: 32px;
    height: 32px;
    background: rgba(0, 0, 0, 0.2);
    border-radius: 50%;
    text-align: center;
    line-height: 32px;
    opacity: 0;
    pointer-events: auto;
    cursor: pointer;
    i {
      width: 16px;
      height: 16px;
    }
  }
}
.carousel-indicator {
  position: relative;
  left: 0;
  bottom: -1px;
  z-index: 2;
  width: 100%;
  text-align: center;
  span {
    display: inline-block;
    width: 30px;
    height: 2px;
    background: rgba(255, 255, 255, 0.2);
    border-radius: 50%;
    cursor: pointer;
    ~ span {
      margin-left: 12px;
    }
    &.active {
      background: #fff;
    }
  }
}
</style>
